import React, { memo, useEffect, useState } from "react";
import "../style/components-style/EnvFactorTable.css";
import config from "../config";
import Grid from "./Grid";
import ProcessEnvFactorButton from "../environment-page-components/ProcessEnvFactorButton";
import { Icon } from "@iconify/react";
import { translations } from "../app_components/Translation";
import { useAuth } from "../authentication/AuthProvider";

function EnvFactorTable({
  setSelectedEnvFactorData,
  selectedProcess,
  allSelectedProcesses,
  selectedRow,
  setEnvFactorTableGridApi,
  isSupplier,
  selectedLanguage,
  setAllEnvFactorRows,
  archive,
  isArivuLibrary,
  isAdmin,
  isStandardSuppliersLibrary,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [envFactorRowData, setEnvFactorRowData] = useState([]);
  const [typeValues, setTypeValues] = useState([]);
  const [categoryValues, setCategoryValues] = useState({});
  const [descriptionValues, setDescriptionValues] = useState({});
  const [unitOptions, setUnitOptions] = useState({}); // Store unit options for each type-category-description combination
  const [isOpen, setIsOpen] = useState(true);
  const [selectedEnvFactorRows, setSelectedEnvFactorRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const [biogenicTotalGWP, setBiogenicTotalGWP] = useState(0);

  const selectedText = translations[selectedLanguage].envFactorTable;
  const { user } = useAuth();
  const userId = user ? user.username : null;
  const [supplierData, setSupplierData] = useState([]);
  const [totalGWP, setTotalGWP] = useState(0);

  const isEditable =
    !archive &&
    (selectedRow?.collaborator_id == null ||
      selectedRow?.collaborator_id === "" ||
      selectedRow?.collaborator_id === "N/A") &&
    (!isArivuLibrary || isAdmin);

  const distanceUnits = [
    "mm",
    "cm",
    "dm",
    "m",
    "km",
    "inch",
    "ft",
    "mi",
    "nmi",
    "yd",
  ];
  const areaUnits = ["mm2", "cm2", "dm2", "m2", "km2", "inch2", "ft2"];
  const volumeUnits = [
    "mm3",
    "cm3",
    "dm3",
    "m3",
    "km3",
    "L",
    "ml",
    "kl",
    "gal",
    "fl_oz",
    "inch3",
    "ft3",
    "bbl",
  ];
  const weightUnits = ["mg", "g", "kg", "ton", "oz", "lb"];
  const timeUnits = ["s", "min", "h", "d", "wk", "mo", "yr"];
  const transportUnits = ["t.km", "t.mi", "t.nmi"];
  const energyUnits = ["J", "kJ", "GJ", "kWh", "Btu", "mmBTU"];
  const itemUnits = ["unit"];
  const passsengerUnits = ["passenger-mile"];
  const pieceUnits = ["piece"];
  const vehiculeUnits = ["vehicle-mile"];

  // Define predefined unit categories
  const unitValue = {
    mm: distanceUnits,
    km: distanceUnits,
    mm2: areaUnits,
    m2: areaUnits,
    m3: volumeUnits,
    L: volumeUnits,
    liter: volumeUnits,
    kg: weightUnits,
    ton: weightUnits,
    day: timeUnits,
    hr: timeUnits,
    "t.km": transportUnits,
    J: energyUnits,
    kWh: energyUnits,
    unit: itemUnits,
    "passenger-mile": passsengerUnits,
    piece: pieceUnits,
    "vehicle-mile": vehiculeUnits,
  };

  // Fetch unit values based on type, category, and description
  const fetchUnitValues = async (type, category, description) => {
    const condition = `type = '${type}' AND category = '${category}' AND description = '${description}'`;

    try {
      const response = await fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table_name: "emission_info",
          attribute: "*",
          condition: condition.trim(),
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok: " + response.statusText);
      }

      const data = await response.json();
      if (data.length > 0) {
        let unitData = data[0].unit.trim(); // Fetch the Unit from the first matching row
        const correspondingUnits = unitValue[unitData] || [];

        // Update state with fetched units by storing them using a unique key (type-category-description)
        setUnitOptions((prev) => ({
          ...prev,
          [`${type}-${category}-${description}`]: correspondingUnits,
        }));
        return correspondingUnits;
      } else {
        console.log("No matching data found.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Prefetch unit values for all rows to reduce delay on cell click
  const prefetchUnitValuesForAllRows = async () => {
    if (envFactorRowData.length > 0) {
      const fetchPromises = envFactorRowData.map((row) => {
        const { type, category, description } = row;
        if (type && category && description) {
          const key = `${type}-${category}-${description}`;
          if (!unitOptions[key]) {
            return fetchUnitValues(type, category, description);
          }
        }
        return Promise.resolve();
      });

      await Promise.all(fetchPromises);
    }
  };

  useEffect(() => {
    if (envFactorRowData.length > 0) {
      prefetchUnitValuesForAllRows();
    }
  }, [envFactorRowData]);

  // Function to handle cell click and fetch unit values dynamically before opening the editor;
  const handleCellClicked = async (params) => {
    const { type, category, description } = params.data;
    const key = `${type}-${category}-${description}`;

    if (!unitOptions[key]) {
      setIsLoadingUnits(true);
      await fetchUnitValues(type, category, description);
      setIsLoadingUnits(false);

      // After fetching, ensure editor opens
      setTimeout(() => {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: "unit",
        });
      }, 0);
    } else {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: "unit",
      });
    }
  };

  // Fetch units when editing starts (pre-fetch logic)
  const handleCellEditingStarted = async (params) => {
    const { type, category, description } = params.data;
    const key = `${type}-${category}-${description}`;

    if (!unitOptions[key]) {
      await fetchUnitValues(type, category, description);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setEnvFactorTableGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const fetchEnvFactorData = () => {
    if (!allSelectedProcesses || allSelectedProcesses.length === 0) {
      setEnvFactorRowData([]);
      return;
    }

    const processIDs = allSelectedProcesses.map((process) => process["id"]);
    const fetchPromises = processIDs.map((processID) => {
      const url = new URL(`${config.apiUrl}/api/get-env-factor-by-process-id`);
      url.searchParams.append("process_id", processID);

      return fetch(url.toString(), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then(async (response) => {
          console.log("Response:", response);

          // Check if the response is not OK
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();

          // Check for the custom message indicating no environmental factors found
          if (
            data.message &&
            data.message ===
            "No environmental factors found for the provided process ID"
          ) {
            console.warn(
              `No environmental factors found for process ID: ${processID}`
            );
            return []; // Return an empty array if no factors found
          }

          return data; // Return the actual data if found
        })
        .catch((error) => {
          console.error(
            `Error fetching data for process ID ${processID}:`,
            error
          );
          return []; // Return an empty array in case of error
        });
    });

    Promise.all(fetchPromises)
      .then((results) => {
        const combinedData = results.flat();
        setEnvFactorRowData(combinedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };

  useEffect(() => {
    fetchEnvFactorData();
  }, [allSelectedProcesses]);

  useEffect(() => {
    if (!gridApi) return;

    const onSelectionChanged = () => {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedEnvFactorData(selectedData);
      setSelectedEnvFactorRows(selectedData);
    };

    gridApi.addEventListener("selectionChanged", onSelectionChanged);
    return () => {
      gridApi.removeEventListener("selectionChanged", onSelectionChanged);
    };
  }, [gridApi, setSelectedEnvFactorData]);

  useEffect(() => {
    setAllRows(envFactorRowData);
    setAllEnvFactorRows(envFactorRowData);
  }, [envFactorRowData]);

  function formatFloatValue(value, decimalPlaces) {
    return Number.parseFloat(value).toFixed(decimalPlaces);
  }

  useEffect(() => {
    fetch(`${config.apiUrl}/get_type_dropdown_data`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          if (!isSupplier) {
            data.data.push("Raw Material (Supplier)");
          }
          setTypeValues(data.data);
        } else {
          console.error("Failed to fetch type values:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching type data:", error);
      });
  }, [isSupplier]);

  const fetchCategoryValues = (type) => {
    if (type === "Raw Material (Supplier)") {
      const supplierCategories = supplierData
        .map((supplier) => supplier.supplier)
        .filter((value, index, self) => value && self.indexOf(value) === index)
        .sort(); // Sort categories alphabetically

      setCategoryValues((prev) => ({
        ...prev,
        [type]: supplierCategories,
      }));
    } else {
      fetch(`${config.apiUrl}/get_category_dropdown_data?type=${type}`)
        .then((response) => response.json())
        .then((data) => {
          setCategoryValues((prev) => ({
            ...prev,
            [type]: data.data || [],
          }));
        })
        .catch((error) =>
          console.error("Error fetching category values:", error)
        );
    }
  };

  const fetchDescriptionValues = (type, category) => {
    if (type === "Raw Material (Supplier)") {
      const descriptionValues = supplierData
        .filter((supplier) => supplier.supplier === category)
        .map((supplier) => supplier.product)
        .filter((value, index, self) => value && self.indexOf(value) === index);

      setDescriptionValues((prev) => ({
        ...prev,
        [`${type}-${category}`]: descriptionValues,
      }));
    } else {
      fetch(
        `${config.apiUrl}/get_description_dropdown_data?type=${type}&category=${category}`
      )
        .then((response) => response.json())
        .then((data) => {
          setDescriptionValues((prev) => ({
            ...prev,
            [`${type}-${category}`]: data.data || [],
          }));
        })
        .catch((error) =>
          console.error("Error fetching description values:", error)
        );
    }
  };

  const handleRowDataChanged = (event) => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        if (node.data.type) {
          fetchCategoryValues(node.data.type);
          if (node.data.category) {
            fetchDescriptionValues(node.data.type, node.data.category);
          }
        }
      });
    }
  };

  const cellValueChanged = (params) => {
    const { colDef, oldValue, newValue, data } = params;

    if (colDef.field === "type" && oldValue !== newValue) {
      fetchCategoryValues(newValue);
      data.category = null;
      data.description = null;
      data.unit = null;
    }

    if (colDef.field === "category" && oldValue !== newValue) {
      fetchDescriptionValues(data.type, newValue);
      data.description = null;
      data.unit = null;
    }

    if (
      ["quantity", "factor", "gwp_unit", "uncertainty"].includes(colDef.field)
    ) {
      if (data.quantity && data.factor && data.gwp_unit && data.uncertainty) {
        const value =
          data.quantity * data.factor * data.gwp_unit * data.uncertainty;
        data.gwp_total =
          value < 0.0001 ? value.toExponential(2) : formatFloatValue(value, 2);
      } else {
        data.gwp_total = 0;
      }

      gridApi.refreshCells({ rowNodes: [params.node], force: true });
    }

    if (
      colDef.field === "description" &&
      data.type === "Raw Material (Supplier)"
    ) {
      const selectedProduct = newValue;
      const matchingSupplier = supplierData.find(
        (supplier) => supplier.product === selectedProduct
      );

      if (matchingSupplier) {
        data.unit = matchingSupplier.functional_unit;
        data.gwp_unit = matchingSupplier.gwp_total;

        gridApi.refreshCells({ rowNodes: [params.node], force: true });
      }
    }

    const updatedTotalGWP = calculateTotalGWP();
    console.log("Updated total GWP:", updatedTotalGWP);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener("cellValueChanged", cellValueChanged);
      gridApi.addEventListener("rowDataUpdated", handleRowDataChanged);
      gridApi.addEventListener("modelUpdated", handleRowDataChanged);
    }

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("cellValueChanged", cellValueChanged);
        gridApi.removeEventListener("rowDataUpdated", handleRowDataChanged);
        gridApi.removeEventListener("modelUpdated", handleRowDataChanged);
      }
    };
  }, [gridApi, categoryValues, descriptionValues]);

  useEffect(() => {
    fetchSupplierData();
  }, []);

  const envFactorGridProps = {
    columnDefs: [
      {
        headerName: selectedText.type,
        field: "type",
        cellEditor: "agSelectCellEditor",
        editable: isEditable,
        cellEditorParams: {
          values: typeValues,
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        minWidth: 150,
        cellStyle: { textAlign: "left" },
        onCellClicked: (params) => {
          fetchCategoryValues(params.data.type);
          fetchDescriptionValues(params.data.type, params.data.category);
        },
      },
      {
        headerName: selectedText.category,
        field: "category",
        cellEditor: "agSelectCellEditor",
        editable: isEditable,
        cellEditorParams: (params) => {
          const values = categoryValues[params.data.type] || [];
          return { values };
        },
        minWidth: 125,
        cellStyle: { textAlign: "left" },
        onCellClicked: (params) => {
          fetchCategoryValues(params.data.type);
          fetchDescriptionValues(params.data.type, params.data.category);
        },
      },
      {
        headerName: selectedText.description,
        field: "description",
        cellEditor: "agSelectCellEditor",
        editable: isEditable,
        cellEditorParams: (params) => {
          const key = `${params.data.type}-${params.data.category}`;
          const values = descriptionValues[key] || [];
          return { values };
        },
        minWidth: 150,
        cellStyle: { textAlign: "left" },
        onCellEditingStarted: (params) => {
          fetchDescriptionValues(params.data.type, params.data.category);
        },
      },
      {
        headerName: selectedText.unit,
        field: "unit",
        cellEditor: "agSelectCellEditor",
        editable: (params) =>
          params.data.type !== "Raw Material (Supplier)" ? isEditable : false,
        cellEditorParams: (params) => {
          const key = `${params.data.type}-${params.data.category}-${params.data.description}`;
          const values = unitOptions[key] || unitValue[params.data.type] || [];
          return { values };
        },
        minWidth: 80,
        maxWidth: 80,
        cellStyle: { textAlign: "left" },
        onCellClicked: handleCellClicked, // Pre-fetch units when the cell is clicked
        onCellEditingStarted: handleCellEditingStarted, // Pre-fetch units when editing starts
      },
      {
        headerName: selectedText.quantity,
        field: "quantity",
        filter: false,
        maxWidth: 90,
        cellEditor: "agNumberCellEditor",
        editable: isEditable,
        valueFormatter: (params) => formatFloatValue(params.value, 3),
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
      {
        headerName: selectedText.factor,
        field: "factor",
        filter: false,
        minWidth: 70,
        maxWidth: 80,
        cellEditor: "agNumberCellEditor",
        editable: isEditable,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
      {
        headerName: selectedText.uncertainty,
        field: "uncertainty",
        filter: false,
        minWidth: 75,
        maxWidth: 120,
        cellEditor: "agNumberCellEditor",
        editable: isEditable,
        valueFormatter: (params) => formatFloatValue(params.value, 3),
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
      {
        headerName: selectedText.gwpUnit,
        field: "gwp_unit",
        filter: false,
        editable: false,
        valueFormatter: (params) => {
          const value = params.value;
          if (value !== null && value !== undefined) {
            return value < 0.001
              ? value.toExponential(3)
              : formatFloatValue(value, 3);
          }
          return value;
        },
        minWidth: 110,
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
      {
        headerName: selectedText.gwpTotal,
        field: "gwp_total",
        filter: false,
        editable: false,
        valueGetter: (params) => {
          if (
            params.data.quantity &&
            params.data.factor &&
            params.data.gwp_unit &&
            params.data.uncertainty
          ) {
            const value =
              params.data.quantity *
              params.data.factor *
              params.data.gwp_unit *
              params.data.uncertainty;
            return value < 0.0001
              ? value.toExponential(2)
              : formatFloatValue(value, 2);
          } else {
            return 0;
          }
        },
        minWidth: 125,
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
    ],
    rowSelection: "multiple",
    rowData: envFactorRowData,
    onGridReady,
  };

  const toggleContainer = () => setIsOpen(!isOpen);

  const fetchSupplierData = () => {
    if (!userId) return;

    const tableName = "suppliers_products";
    const condition = `((user_id = '${userId}' AND (collaborator_id IS NULL OR collaborator_id = '' OR collaborator_id = 'N/A')) OR (collaborator_id = '${userId}'))`;

    fetch(`${config.apiUrl}/fetch_data`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        table_name: tableName,
        attribute: "*",
        condition: condition,
      }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(
            "Network response was not ok: " + response.statusText
          );
        return response.json();
      })
      .then((data) => setSupplierData(data || []))
      .catch((error) => console.error("Error fetching data:", error));
  };

  // Updated calculateTotalGWP function to calculate based on selected rows or all rows
  const calculateTotalGWP = () => {
    const dataToSum =
      selectedEnvFactorRows.length > 0
        ? selectedEnvFactorRows
        : envFactorRowData;

    const total = dataToSum
      .reduce((total, row) => {
        const gwpTotal =
          row.gwp_total && !isNaN(row.gwp_total)
            ? parseFloat(row.gwp_total)
            : 0;
        return total + gwpTotal;
      }, 0)
      .toFixed(2);

    const biogenicTotal = dataToSum
      .reduce((total, row) => {
        const biogenicGWP =
          row.biogenic_gwp && !isNaN(row.biogenic_gwp)
            ? parseFloat(row.biogenic_gwp)
            : 0;
        return total + biogenicGWP;
      }, 0)
      .toFixed(2);

    setTotalGWP(total);
    setBiogenicTotalGWP(biogenicTotal);
  };

  useEffect(() => {
    calculateTotalGWP();
  }, [envFactorRowData, selectedEnvFactorRows]);

  return (
    <div
      className={`env-factor-table ${isOpen ? "expanded" : ""}`}
      style={{
        backgroundColor:
          archive || isArivuLibrary || isStandardSuppliersLibrary
            ? "#faf4e8"
            : "white",
      }}
    >
      <div className="container-header-envfactor">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() =>
          (document.querySelector(
            ".container-header-envfactor h2"
          ).style.color = "grey")
          } // Hover color
          onMouseLeave={() =>
          (document.querySelector(
            ".container-header-envfactor h2"
          ).style.color = "black")
          } // Default color
          style={{ cursor: "pointer" }}
        >
          {selectedText.envFactors}
        </h2>
      </div>
      {isOpen && (
        <div className="grid-container-env">
          <div className="grid-wrapper env-factor-grid-wrapper">
            <Grid {...envFactorGridProps} customMinHeight={260} />
          </div>

          <div className="gwp-total-env">
            <span className="gwp-total-env-text">
              {selectedText.biogenicTotal} {biogenicTotalGWP}{" "}
            </span>
            <span className="env-gwp-divider"> | </span>
            <span className="gwp-biogenic-total-env-text">
              {" "}
              {selectedText.fossilTotal} {totalGWP}
            </span>
          </div>
          <div className="button-container-env">
            <ProcessEnvFactorButton
              selectedRows={envFactorRowData}
              selectedProcessesRows={selectedEnvFactorRows}
              allSelectedProcesses={allSelectedProcesses}
              tableGridApi={gridApi}
              isForProcess={false}
              product={null}
              design={null}
              process={selectedProcess}
              allRows={allRows}
              refreshData={fetchEnvFactorData}
              selectedLanguage={selectedLanguage}
              isEditable={isEditable}
              archive={archive}
              isAdmin={isAdmin}
              isArivuLibrary={isArivuLibrary}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(EnvFactorTable);
