import React, { useState } from "react";
import "../../style/sidemenu-pages-style/EPDLifeCycleAssessment.css";
import config from "../../config";
import { translations } from "../../app_components/Translation";

function EPDLifeCycleAssessment({ selectedLanguage, firstSelectedRowData }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const selectedText =
    translations[selectedLanguage]?.lifeCycleAssessment || {};

  const toggleContainer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="lca-container"
      style={{
        backgroundColor: "#faf4e8",
      }}
    >
      <div className="container-header">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            color: isHovered ? "grey" : "black",
          }}
        >
          {selectedText.title || "Life Cycle Assessment"}
        </h2>
      </div>
      {isOpen && (
        <>
          <div className="section general-info-layout">
            <div className="general-info-header">
              <h2 className="centered-title">
                {selectedText.generalInformation || "General Information"}
              </h2>
            </div>
            <div className="general-info-content">
              {[
                {
                  label: selectedText.referencePCR || "Reference PCR",
                  value: firstSelectedRowData?.PCR || "",
                },
                {
                  label: selectedText.declaredUnit || "Declared Unit",
                  value: firstSelectedRowData?.["Declared Unit"] || "",
                },
                {
                  label: selectedText.epdUrl || "EPD URL",
                  value: firstSelectedRowData?.["Original EC3 Link"] || "",
                },
                {
                  label: selectedText.lcaSoftware || "LCA Software",
                  value: firstSelectedRowData?.lcaSoftware || "",
                },
                {
                  label: selectedText.pcrDocument || "PCR Document",
                  value: firstSelectedRowData?.["PCR Document"] || "",
                },
                {
                  label: selectedText.lciaMethodology || "LCIA Methodology",
                  value: firstSelectedRowData?.["Primary LCIA Method"] || "",
                },
              ].map((row, index) => (
                <div
                  className={`general-info-row ${index % 2 === 0 ? "row-light" : "row-dark"
                    }`}
                  key={index}
                >
                  <label className="general-info-label">{row.label}</label>
                  <textarea
                    readOnly
                    className="general-info-textarea"
                    value={row.value}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="section">
            <h2>{selectedText.cutOff || "Cut-Off"}</h2>
            <textarea readOnly className="readonly-textarea" />
          </div>
          <div className="section">
            <h2>
              {selectedText.allocationProcedure || "Allocation Procedure"}
            </h2>
            <textarea readOnly className="readonly-textarea" />
          </div>
          <div className="section">
            <h2>{selectedText.primarySources || "Primary Sources"}</h2>
            <textarea readOnly className="readonly-textarea" />
          </div>
          <div className="section">
            <h2>{selectedText.interpretation || "Interpretation"}</h2>
            <textarea readOnly className="readonly-textarea" />
          </div>
          <div className="section">
            <h2>
              {selectedText.references || "References / Environmental Factors"}
            </h2>
            <textarea readOnly className="readonly-textarea" />
          </div>
        </>
      )}
    </div>
  );
}

export default EPDLifeCycleAssessment;
