import React, { useState } from "react";
import "./style/App.css";
import Login from "./pages/Login";
import Footer from "./app_components/Footer";
import HomePage from "./pages/HomePage";
import Supplier from "./pages/Supplier";
import Utilizer from "./pages/Utilizer";
import Comparison from "./pages/Comparison";
import NetZero from "./pages/NetZero";
import Circularity from "./pages/Circularity";
import Support from "./sidemenu-pages/Support";
import Settings from "./sidemenu-pages/Settings";
import Profile from "./sidemenu-pages/Profile";
import ArivuLibrary from "./sidemenu-pages/ArivuLibrary";
import StandardSuppliersLibrary from "./sidemenu-pages/StandardSuppliersLibrary";
import UserLibrary from "./sidemenu-pages/UserLibrary";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import TermsAndConditions from "./pages/TermsAndConditions";
import { AuthProvider } from "./authentication/AuthProvider";
import PrivateRoute from "./authentication/PrivateRoute";
import Reuse from "./pages/Reuse";
import { PendingUserProvider } from "./authentication/PendingUserContext";
import Register from "./pages/Register";
import HomeViewType from "./pages/HomeViewType";
import HomeOrganizational from "./pages/HomeOrganizational";
import OrganizationalSupplyChain from "./organizational-pages/OrganizationalSupplyChain";
import OrganizationalOrganization from "./organizational-pages/OrganizationalOrganization";
import OrganizationalComparison from "./organizational-pages/OrganizationalComparison";
import OrganizationalCorporate from "./organizational-pages/OrganizationalCorporate";
import FleetPerformance from "./fleet-pages/FleetPerformance";

import config from "./config";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [currentVersion, setCurrentVersion] = useState(config.version);

  const sharedRoutes = [
    { path: "/support", element: <Support selectedLanguage={selectedLanguage} /> },
    { path: "/settings", element: <Settings selectedLanguage={selectedLanguage} /> },
    { path: "/profile", element: <Profile selectedLanguage={selectedLanguage} /> },
    { path: "/homeviewtype", element: <HomeViewType selectedLanguage={selectedLanguage} currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} /> },
  ];

  const versionSpecificRoutes = [
    { path: "/home", element: <HomePage selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/homeorganizational", element: <HomeOrganizational selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/supplier", element: <Supplier selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/utilizer", element: <Utilizer selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/arivulibrary", element: <ArivuLibrary selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/standardsupplierslibrary", element: <StandardSuppliersLibrary selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/userlibrary", element: <UserLibrary selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/comparison", element: <Comparison selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/netzero", element: <NetZero selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/circularity", element: <Circularity selectedLanguage={selectedLanguage} />, showIn: "abc" },
    { path: "/fleet/performance", element: <FleetPerformance selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/organizationalsupplychain", element: <OrganizationalSupplyChain selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/organizationalorganization", element: <OrganizationalOrganization selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/organizationalcorporate", element: <OrganizationalCorporate selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/organizationalcomparison", element: <OrganizationalComparison selectedLanguage={selectedLanguage} />, showIn: "flots" },
    { path: "/reuse", element: <Reuse selectedLanguage={selectedLanguage} />, showIn: "abc" },
  ];

  // Filter version-specific routes based on the current version
  const filteredRoutes = versionSpecificRoutes.filter((route) => route.showIn === currentVersion);

  return (
    <AuthProvider>
      <PendingUserProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/login" element={<Login selectedLanguage={selectedLanguage} />} />
              <Route path="/register" element={<Register selectedLanguage={selectedLanguage} />} />
              <Route path="/" element={<Navigate replace to="/login" />} />
              <Route path="/terms_and_conditions" element={<TermsAndConditions selectedLanguage={selectedLanguage} />} />

              <Route element={<PrivateRoute />}>
                {sharedRoutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}

                {filteredRoutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Route>
            </Routes>
            <Footer selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
          </div>
        </Router>
      </PendingUserProvider>
    </AuthProvider>
  );
}

export default App;
