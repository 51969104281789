import React, { memo, useEffect, useState } from "react";
import config from "../config";
import { useAuth } from "../authentication/AuthProvider";
import Tooltip from "../app_components/Tooltip";
import { Icon } from "@iconify/react";

function CircularityButton({
  onClick,
  className,
  disabled,
  tooltip,
  icon,
  children,
  data,
}) {
  const [isDisabled, setIsDisabled] = useState(disabled);
  const { user } = useAuth();
  
  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleClick = async () => {
    if (!data || data.length === 0) {
      console.warn("No data to update.");
      return;
    }

    try {
      await Promise.all(
        data.map(async (row) => {
          // Create a copy of row without the 'description' field
          const { description, ...filteredRow } = row;

          const response = await fetch(`${config.apiUrl}/update_data`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              table_name: "circularity_indicator",
              data: filteredRow, // Send data without 'description'
              condition: `id = '${row.id}'`,
            }),
          });

          if (!response.ok) {
            throw new Error(`Failed to update row with ID: ${row.id}`);
          }

          const result = await response.json();
          console.log(`Row ${row.id} updated successfully:`, result);
        })
      );
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={isDisabled}
      className={`button-container-circularity ${className}`}
      style={{
        display: 'flex',
        justifyContent: 'right',
        marginTop: '20px',
        marginRight: '10px',
        padding: '8px 16px',
        backgroundColor: '#6ba06c',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#639664'}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#6ba06c'}
    >
      {icon && <Icon icon={icon} className="button-icon" />}
      {children || "Save"}
      {tooltip && <Tooltip text={tooltip} />}
    </button>
  );
}

export default memo(CircularityButton);
