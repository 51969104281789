import '../style/components-style/Support.css';
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { translations } from '../app_components/Translation';
import Header from '../app_components/Header';
import userGuide from '../assets/logo/userguide.pdf';

function Support({ selectedLanguage }) {
    const selectedText = translations[selectedLanguage]?.support || {};
    console.log(selectedLanguage, selectedText);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div className="support">
            <Header selectedLanguage={selectedLanguage} />
            <div className="support-container">
                <div className="support-navigation">
                    <nav>
                        <ul>
                            <li><a>{selectedText.userGuide}</a></li>
                            {/* Not yet connected */}
                        </ul>
                    </nav>
                </div>
                <div className="pdf-viewer">
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}>
                        <Viewer
                            fileUrl={userGuide}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </Worker>
                </div>
            </div>
        </div>
    );
}

export default Support;
