import React, { useState, useEffect } from "react";
import "../style/components-style/MaterialPhoto.css"; // Import the CSS file
import config from "../config";

const MaterialPhoto = ({
  selectedRow,
  userid,
  additionalInfo,
  editableAdditionalInfo,
  handleInputChange,
  handleSave,
  pageType,
  circularityPage,
  netZeroPage,
}) => {
  const [materialPhoto, setMaterialPhoto] = useState(null);
  const [error, setError] = useState(false); // Track if no image is available
  const [fileName, setFileName] = useState(""); // State to hold the selected file name
  const [file, setFile] = useState(null);

  const isEditable =
    additionalInfo?.collaborator_id === null ||
    userid === additionalInfo.collaborator_id ||
    additionalInfo?.collaborator_id === "";

  useEffect(() => {
    const fetchMaterialPhoto = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/get_material_photo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            table_name:
              pageType === "supplier"
                ? "suppliers_products"
                : "design_installations",
            id: selectedRow?.id,
          }),
        });

        if (response.status === 404) {
          setError(true); // No image found
          setMaterialPhoto(null);
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch material photo");
        }

        const result = await response.json();
        if (result.material_photo) {
          setMaterialPhoto(result.material_photo); // Directly set the base64 string
          setError(false);
        } else {
          setError(true);
          setMaterialPhoto(null);
        }
        console.log("Material photo fetched:", result);
      } catch (error) {
        console.error("Error fetching material photo:", error);
        setError(true);
      }
    };

    if (selectedRow?.id) {
      fetchMaterialPhoto();
    }
  }, [selectedRow, pageType]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name); // Set the file name when a file is selected
      setFile(selectedFile); // Set the file state
      handleInputChange(event); // Call the original input change handler
    }
  };

  const combinedHandleSave = async () => {
    if (handleSave) {
      handleSave();
    }

    if (pageType === "supplier") {
      try {
        const data = {
          table_name: "env_factors",
          data: {
            unit: editableAdditionalInfo.functional_unit,
          },
          condition: `category = '${additionalInfo.product}' AND description = '${additionalInfo.supplier}'`,
        };

        // Check if a file is selected
        if (file) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            // Add base64 encoded file content to the data object
            const base64String = reader.result.split(',')[1];
            if (base64String) {
              data.data.material_photo = base64String;
              await sendUpdateRequest(data);
            }
          };
          reader.readAsDataURL(file);
        } else {
          // Skip `material_photo` if no file is selected
          await sendUpdateRequest(data);
        }
      } catch (error) {
        console.error("Error updating data:", error);
        alert("Error updating data");
      }
    }
  };

  const sendUpdateRequest = async (data) => {
    const response = await fetch(`${config.apiUrl}/update_data`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Failed to update data");
    }

    const result = await response.json();
    if (result.status !== "success") {
      alert("Failed to update data");
    }
  };

  const units = [
    "mg",
    "g",
    "kg",
    "ton",
    "oz",
    "lb",
    "kWh",
    "J",
    "kJ",
    "GJ",
    "Btu",
    "mmBTU",
    "t.km",
    "t.mi",
    "t.nmi",
    "s",
    "min",
    "h",
    "day",
    "wk",
    "mo",
    "yr",
    "mm3",
    "cm3",
    "dm3",
    "m3",
    "km3",
    "inch3",
    "ft3",
    "L",
    "gal",
    "fl_oz",
    "barrel",
    "ml",
    "mm2",
    "cm2",
    "dm2",
    "m2",
    "km2",
    "inch2",
    "ft2",
    "mm",
    "cm",
    "dm",
    "m",
    "km",
    "inch",
    "ft",
    "yd",
    "mi",
    "nmi",
    "unit",
    "piece",
  ];

  return (
    <div className="material-photo">
      <form>
        {(pageType === "supplier" || pageType === "valueChain") && (
          <div>
            <label>Functional Unit:</label>
            <select
              name="functional_unit"
              value={editableAdditionalInfo.functional_unit || ""}
              onChange={handleInputChange}
              disabled={!isEditable}
            >
              <option value="">Select a unit</option>
              {units.map((unit) => (
                <option key={unit} value={unit}>
                  {unit}
                </option>
              ))}
            </select>
          </div>
        )}
        {(pageType === "supplier" || pageType === "utilizer") && (
          <div>
            <label>Service Life (Years):</label>
            <input
              type="text"
              name="service_life"
              value={editableAdditionalInfo.service_life || ""}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />
          </div>
        )}
        {(pageType === "supplier" || pageType === "valueChain") && (
          <div>
            <label>Specifications:</label>
            <input
              type="text"
              name="specifications"
              value={editableAdditionalInfo.specifications || ""}
              onChange={handleInputChange}
              readOnly={!isEditable}
            />
          </div>
        )}
        <div>
          <label>Date Modified:</label>
          <span>{editableAdditionalInfo.date_modified || ""}</span>
        </div>
        {error ? (
          <p className="no-image">No image</p> // Updated class for error message
        ) : (
          <img
            src={materialPhoto || ""}
            alt="Material"
            style={{ display: materialPhoto ? "block" : "none" }} // Hide img tag if no photo
          />
        )}
        {(pageType === "supplier" ||
          pageType === "utilizer" ||
          pageType === "organization" ||
          pageType === "corporate") && (
            <div className="file-container">
              {" "}
              {/* Updated class for the file input container */}
              <label htmlFor="material-photo-input" className="file-upload-btn">
                Choose file
              </label>
              {fileName ? (
                <p className="file-name">{fileName}</p> // Display the selected file name
              ) : (
                <p className="file-name">No file chosen</p> // Display default text when no file is selected
              )}
              <input
                id="material-photo-input"
                type="file"
                accept="image/*"
                name="material_photo"
                onChange={handleFileChange} // Update the handler to manage file name
                readOnly={!isEditable}
              />
            </div>
          )}
        {!(circularityPage || netZeroPage) && (
          <button
            type="button"
            onClick={combinedHandleSave}
            disabled={!isEditable}
            className={!isEditable ? "disabled" : ""}
          >
            Save Details
          </button>
        )}
      </form>
    </div>
  );
};

export default MaterialPhoto;
