import React, { useState, useEffect, useMemo } from "react";
import "../style/pages-style/Circularity.css";
import Header from "../app_components/Header";
import TableComponent from "../environment-page-components/Table";
import "../style/components-style/Table.css";
import config from "../config";
import Grid from "../environment-page-components/Grid";
import { translations } from "../app_components/Translation";
import InputMaterialTable from "../circularity-components/InputMaterialTable";
import OutputMaterialTable from "../circularity-components/OutputMaterialTable";
import EndOfLifeTreatmentTable from "../circularity-components/EndOfLifeTreatmentTable";
import UtilityFactorTable from "../circularity-components/UtilityFactorTable";
import MciTable from "../circularity-components/MciTable";
import GwpVsMciChart from "../circularity-components/GwpVsMciChart";

function Circularity({ selectedLanguage }) {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [mainTableGridApi, setMainTableGridApi] = useState(null);
  const [trackViewState, setTrackViewState] = useState(false);
  const [allTableRows, setAllTableRows] = useState([]);
  const [pageType, setPageType] = useState("supplier");
  const [circularityPageType, setCircularityPageType] = useState("utility");
  const [envFactors, setEnvFactors] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const circularityPage = true;
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [quantitySum, setQuantitySum] = useState(0);
  const [gwp_totalSum, setGwpTotalSum] = useState(0);
  const [chartData, setChartData] = useState([]); // Define chartData state
  const [normalizedGwpData, setNormalizedGwpData] = useState([]); // Define normalizedGwpData state
  console.log(fetchedData); // Debugging line

  const selectedText = translations[selectedLanguage].circularityPage;

  useEffect(() => {
    const sumQuantities = envFactors.reduce(
      (sum, material) => sum + (material.quantity || 0),
      0
    );
    setQuantitySum(sumQuantities);

    const sumGwp = envFactors.reduce(
      (sum, material) => sum + (material.gwp_total || 0),
      0
    );
    setGwpTotalSum(sumGwp);
  }, [envFactors]);

  useEffect(() => {
    const fetchEnvFactorsForSelectedRows = async () => {
      let newEnvFactors = [];
      for (const row of selectedRowData) {
        const processIds = await fetchProcessIdsByRowId(row.id);
        const filteredProcessIds = processIds.filter(
          (process) => process.lca_stage && process.lca_stage.startsWith("A1")
        );
        for (const process of filteredProcessIds) {
          const factors = await fetchEnvFactorsByProcessId(process.id);
          console.log(factors); // Debugging line
          if (Array.isArray(factors)) {
            // Ensure factors is iterable
            newEnvFactors = [...newEnvFactors, ...factors];
          } else {
            console.warn("Factors is not an array", factors);
          }
        }
      }
      newEnvFactors.sort((a, b) => a.description.localeCompare(b.description));
      setEnvFactors(newEnvFactors);
    };

    if (selectedRowData.length > 0) {
      fetchEnvFactorsForSelectedRows();
    } else {
      setEnvFactors([]); // Clear envFactors if no rows are selected
    }
  }, [selectedRowData]);

  useEffect(() => {
    const fetchDataForSelectedMaterials = async () => {
      if (selectedMaterials.length === 0) return;

      const envIds = selectedMaterials.map((material) => `'${material.id}'`);
      const condition = `env_id IN (${envIds.join(",")})`;

      try {
        const response = await fetch(`${config.apiUrl}/fetch_data`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            table_name: "circularity_indicator",
            condition: condition,
          }),
        });

        const data = await response.json();
        const dataWithDetails = data.map((item, index) => ({
          ...item,
          description: selectedMaterials[index].description,
          category: selectedMaterials[index].category,
          normalizedMass: selectedMaterials[index].normalizedMass,
          quantity: selectedMaterials[index].quantity,
          quantitySum: quantitySum,
        }));
        console.log(dataWithDetails); // Debugging line
        dataWithDetails.sort((a, b) => a.description.localeCompare(b.description));
        setFetchedData(dataWithDetails); // Update the state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataForSelectedMaterials();
  }, [selectedMaterials, circularityPageType]);

  const fetchProcessIdsByRowId = async (rowId) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/api/get-process-ids-by-row-id?row_id=${rowId}`
      );
      return await response.json();
    } catch (error) {
      console.error("Error fetching process IDs:", error);
      return [];
    }
  };

  const fetchEnvFactorsByProcessId = async (processId) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/api/get-env-factor-by-process-id?process_id=${processId}`
      );
      const data = await response.json();

      // Check if data is an array; if not, return an empty array
      if (Array.isArray(data)) {
        return data;
      } else {
        console.warn("Factors is not an array", data);
        return []; // Default to an empty array if data is not iterable
      }
    } catch (error) {
      console.error("Error fetching environmental factors:", error);
      return []; // Return empty array on error
    }
  };

  const toggleContainer = () => setIsOpen(!isOpen);

  // Calculate gwpTotal
  const gwpTotal = envFactors.reduce(
    (total, material) => total + material.gwp,
    0
  );

  // useMemo for precomputing normalized mass
  const processedEnvFactors = useMemo(() => {
    return envFactors.map((material) => ({
      ...material,
      normalizedMass:
        quantitySum > 0 ? (material.quantity / quantitySum).toFixed(2) : "0.00",
      normalizedGwp:
        gwp_totalSum > 0
          ? (material.gwp_total / gwp_totalSum).toFixed(2)
          : "0.00",
    }));
  }, [envFactors, quantitySum, gwp_totalSum]);

  useEffect(() => {
    // Extract normalized GWP values and update the state
    const normalizedGwpValues = processedEnvFactors.map(
      (material) => material.normalizedGwp
    );
    setNormalizedGwpData(normalizedGwpValues);
  }, [processedEnvFactors]);

  const columnDefs = [
    {
      headerName: selectedText.type,
      field: "type",
      sortable: true,
      filter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: selectedText.category,
      field: "category",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.description,
      field: "description",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.unit,
      field: "unit",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.quantity,
      field: "quantity",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.normalizedMass,
      field: "normalizedMass",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.factor,
      field: "factor",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.gwpTotal,
      field: "gwp_total",
      sortable: true,
      filter: true,
    },
    {
      headerName: selectedText.normalizedGwp,
      field: "normalizedGwp",
      sortable: true,
      filter: true,
    },
  ];

  return (
    <div className="supplier-utilizer">
      <div className="circularity-page">
        <Header selectedLanguage={selectedLanguage} />
        <div
          className={`main-table-container-circularity ${circularityPage ? "circularity-active" : ""
            }`}
        >
          <TableComponent
            setSelectedRowData={setSelectedRowData}
            setMainTableGridApi={setMainTableGridApi}
            setTrackViewState={setTrackViewState}
            selectedLanguage={selectedLanguage}
            setAllTableRows={setAllTableRows}
            childTableRows={[]}
            pageType={pageType}
            setPageType={setPageType}
            circularityPage={circularityPage}
          />

          {/* Materials Table */}
          <div className="circularity-container">
            <div className="container-header-material">
              <h2
                onClick={toggleContainer}
                onMouseEnter={() =>
                (document.querySelector(
                  ".container-header-material h2"
                ).style.color = "grey")
                }
                onMouseLeave={() =>
                (document.querySelector(
                  ".container-header-material h2"
                ).style.color = "black")
                }
                style={{ cursor: "pointer" }}
              >
                {selectedText.materials}
              </h2>
            </div>
            {isOpen && (
              <>
                <Grid
                  rowData={processedEnvFactors} // Pass the processed data
                  columnDefs={columnDefs}
                  rowSelection="multiple"
                  onSelectionChanged={(event) => {
                    setSelectedMaterials(event.api.getSelectedRows());
                  }}
                />
                <div
                  className="quantity-sum-bar"
                  style={{
                    backgroundColor: "#F7F7F7",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  Total Quantity: {quantitySum}
                </div>
              </>
            )}
          </div>

          {/* Conditionally Rendered Content */}
          <div className="circularity-container">
            {/* Header and Toggle Switch in the same row */}
            <div className="header-toggle-container">
              <h2 className="circularity-header">
                {circularityPageType === "utility" && selectedText.utility}
                {circularityPageType === "input" && selectedText.inputmaterial}
                {circularityPageType === "output" &&
                  selectedText.outputmaterial}
                {circularityPageType === "end-of-life" &&
                  selectedText.endoflifetreatment}
              </h2>

              {/* Toggle Switch */}
              <div
                className={`circularity-toggle-switch circularity-${circularityPageType}`}
              >
                <div
                  className={`circularity-toggle-option ${circularityPageType === "utility" ? "selected" : ""
                    }`}
                  onClick={() => setCircularityPageType("utility")}
                >
                  {selectedText.utility}
                </div>
                <div
                  className={`circularity-toggle-option ${circularityPageType === "input" ? "selected" : ""
                    }`}
                  onClick={() => setCircularityPageType("input")}
                >
                  {selectedText.input}
                </div>
                <div
                  className={`circularity-toggle-option ${circularityPageType === "output" ? "selected" : ""
                    }`}
                  onClick={() => setCircularityPageType("output")}
                >
                  {selectedText.output}
                </div>
                <div
                  className={`circularity-toggle-option ${circularityPageType === "end-of-life" ? "selected" : ""
                    }`}
                  onClick={() => setCircularityPageType("end-of-life")}
                >
                  {selectedText.endoflife}
                </div>
                <div className="circularity-toggle-slider"></div>
              </div>
            </div>

            {/* Conditional Tables */}
            {circularityPageType === "utility" && (
              <UtilityFactorTable
                data={fetchedData}
                selectedLanguage={selectedLanguage}
              />
            )}
            {circularityPageType === "input" && (
              <InputMaterialTable
                data={fetchedData}
                selectedLanguage={selectedLanguage}
              />
            )}
            {circularityPageType === "output" && (
              <OutputMaterialTable
                data={fetchedData}
                selectedLanguage={selectedLanguage}
              />
            )}
            {circularityPageType === "end-of-life" && (
              <EndOfLifeTreatmentTable
                data={fetchedData}
                selectedLanguage={selectedLanguage}
              />
            )}
          </div>
          <div className="circularity-container">
            <h2 className="circularity-header">MCI Table</h2>
            <MciTable
              selectedLanguage={selectedLanguage}
              fetchedData={fetchedData}
              setChartData={setChartData}
            />
          </div>
          <div className="circularity-container">
            <h2 className="circularity-header">GWP vs MCI Chart</h2>
            <GwpVsMciChart
              mciData={chartData}
              gwpData={normalizedGwpData}
            />{" "}
            {/* Pass chartData and normalizedGwpData to GwpVsMciChart */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Circularity;
