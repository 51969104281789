import "../../style/sidemenu-pages-style/EPDPlot.css";
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { translations } from "../../app_components/Translation";

const EPDPlot = ({ gwpValues, calculateStandardDeviation, selectedLanguage }) => {
  const [gwpStdDev, setGwpStdDev] = useState(null);
  const selectedText = translations[selectedLanguage]?.EPDPlot || {};


  useEffect(() => {
    if (gwpValues && calculateStandardDeviation) {
      // Calculate the standard deviation when gwpValues changes
      const stdDev = calculateStandardDeviation(gwpValues);
      setGwpStdDev(stdDev);
    }
  }, [gwpValues, calculateStandardDeviation]);

  const gwpMin = Math.min(...gwpValues);
  const gwpMax = Math.max(...gwpValues);
  const gwpMean =
    gwpValues.reduce((sum, value) => sum + value, 0) / gwpValues.length || 0;

  // Format the standard deviation when it is available
  const gwpStdDevFormatted = gwpStdDev !== null ? gwpStdDev.toFixed(2) : "N/A";

  return (
    <div
      className="gwp-boxplot"
      style={{
        backgroundColor: "#faf4e8",
      }}
    >
      <Plot
        data={[
          {
            type: "box",
            y: gwpValues,
            name: "GWP",
            boxpoints: "all",
            jitter: 0.3,
            pointpos: 0,
            marker: {
              color: "#3d3d3d",
              size: 6,
              opacity: 0.7,
            },
            line: {
              color: "#75B376",
            },
          },
        ]}
        layout={{
          title: selectedText.GWPDistribution,
          yaxis: {
            title: selectedText.gwpValues,
            range: [gwpMin - 10, gwpMax + 10],
          },
          annotations: [
            {
              x: 1,
              y: gwpMin - 10,
              xref: "paper",
              yref: "y",
              text: `${selectedText.min}: ${gwpMin.toFixed(2)} | ${selectedText.max}: ${gwpMax.toFixed(
                2
              )} | ${selectedText.mean}: ${gwpMean.toFixed(2)} | ${selectedText.stddev}: ${gwpStdDevFormatted}`,
              showarrow: false,
              font: {
                size: 12,
                color: "black",
              },
              xanchor: "right",
              yanchor: "bottom",
              xshift: 40,
              yshift: -40,
            },
          ],
          boxmode: "group",
          showlegend: false,
          margin: { t: 50, b: 50, l: 50, r: 50 },
        }}
      />
    </div>
  );
};

export default EPDPlot;
