import React, { memo, useEffect, useState } from "react";
import "../style/components-style/TableButton.css";
import config from "../config";
import { useAuth } from "../authentication/AuthProvider";
import Tooltip from "../app_components/Tooltip";
import { translations } from "../app_components/Translation";
import { Icon } from "@iconify/react";

function TableButton({
    pageType,
    selectedRows,
    mainTableGridApi,
    allRows,
    fetchData,
    selectedLanguage,
    gridApi,
    archive,
    isArivuLibrary,
    isAdmin
}) {
    const { user } = useAuth();
    const userId = user ? user.username : null;

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("All");
    const [disableSave, setDisableSave] = useState(true);

    const selectedText = translations[selectedLanguage].buttons;

    const onCellValueChanged = (event) => {
        setDisableSave(false);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.addEventListener("cellValueChanged", onCellValueChanged);
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener("cellValueChanged", onCellValueChanged);
            }
        };
    }, [gridApi]);

    const handleOpenConfirmModal = () => {
        setIsConfirmModalOpen(true);
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
    };

    const createNewRowData = () => {
        const now = new Date();
        const commonData = {
            Year: 2023,
            gwp_total: 0.0,
            cost: 0.0,
            date_modified: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`,
            user_id: userId,
        };

        if (pageType === "supplier") {
            return {
                ...commonData,
                quantity: 0,
                leed_category: "",
                product: "",
                supplier: "",
                sub_category: "",
                cost_per_gwp: "N/A",
                functional_unit: "",
            };
        } else if (pageType === "organization") {
            return {
                ...commonData,
                organization: "new organization",
                location: "",
            };
        } else if (pageType === "fleet") {
            return {
                Year: 2023,
                gwp_total: 0.0,
                date_modified: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`,
                user_id: userId,
                fleet: "",
                vehicle_type: "",
                trailer_type: "",
                location: "",
                fuel_consumed: 0,
                distance_travelled: 0,
                distance_per_gwp: 0,
                asset_numbers: JSON.stringify([]),
            };
        } else if (pageType === "utilizer") {
            return {
                ...commonData,
                quantity: 0,
                description: "",
                design_installation: "",
                location: "",
            };
        } else if (pageType === "valueChain") {
            return {
                ...commonData,
                ps_quantity: 0,
                product_service: "new product_service",
                role: "",
                name: "",
                cost_per_gwp: "N/A",
                functional_unit: "",
            };
        } else if (pageType === "corporate") {
            return {
                ...commonData,
                corporate: "new corporate",
                location: "",
            };
        } else {
            return { ...commonData };
        }
    };

    const addRow = () => {
        if (!mainTableGridApi) return;

        const newRow = createNewRowData();
        const tableName = pageType === "supplier" ? "suppliers_products" : pageType === "organization" ? "organizations" : pageType === "utilizer" ? "design_installations" : pageType === "fleet" ? "fleets" : pageType === "valueChain" ? "products_services" : "corporates";

        fetch(`${config.apiUrl}/add_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                table_name: tableName,
                data: newRow,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    mainTableGridApi.applyTransaction({ add: [newRow] });
                    fetchData();
                }
            })
            .catch((error) => console.error("Error adding data:", error));
    };

    const deleteSelectedRow = () => {
        if (!mainTableGridApi) return;
        const selectedData = mainTableGridApi.getSelectedRows();
        const tableName = pageType === "supplier" ? "suppliers_products" : pageType === "organization" ? "organizations" : pageType === "utilizer" ? "design_installations" : pageType === "fleet" ? "fleets" : pageType === "valueChain" ? "products_services" : "corporates";

        selectedData.forEach((row) => {
            fetch(`${config.apiUrl}/delete_data`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ table_name: tableName, id: row.id }),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        mainTableGridApi.applyTransaction({ remove: [row] });
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error deleting data:", error));
        });
        handleCloseConfirmModal();
    };

    const duplicateRow = () => {
        if (!mainTableGridApi || selectedRows.length !== 1) return;

        const selectedRow = selectedRows[0];

        fetch(`${config.apiUrl}/duplicate_table_row`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                row_id: selectedRow.id,
                pageType: pageType,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    fetchData(); // Refresh the data to include the new duplicated row
                }
            })
            .catch((error) => console.error("Error duplicating row:", error));
    };

    const saveTable = () => {
        const currentDate = new Date();
        const tableName = pageType === "supplier" ? "suppliers_products" : pageType === "organization" ? "organizations" : pageType === "utilizer" ? "design_installations" : pageType === "fleet" ? "fleets" : pageType === "valueChain" ? "products_services" : "corporates";
        console.log(allRows);

        allRows.forEach((row) => {
            row.date_modified = currentDate.toISOString().slice(0, 19).replace("T", " ");

            fetch(`${config.apiUrl}/update_data`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    table_name: tableName,
                    data: row,
                    condition: `id = '${row.id}'`,
                }),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error updating data:", error));
        });

        setDisableSave(true);
    };

    const handleExport = () => {
        const selectedRowIds = selectedRows.map((row) => row.id); // Collect all selected row IDs

        fetch(`${config.apiUrl}/api/export-data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                dropdownValue,
                rowIds: selectedRowIds, // Send all selected row IDs
                pageType: pageType,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.blob(); // Get the response as a blob
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `LCA-export-${new Date().toISOString().replace(/[:.]/g, "-")}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); // Clean up
            })
            .catch((error) => console.error("Error exporting data:", error));
    };

    const handleImport = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const promises = Array.from(files).map((file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("userId", userId);
                formData.append("pageType", pageType); // Ensure boolean is converted to a string

                return fetch(`${config.apiUrl}/import_data`, {
                    method: "POST",
                    body: formData,
                })
                    .then(async (response) => {
                        if (!response.ok) {
                            const errorResponse = await response.json(); // Parse error details from the backend
                            throw new Error(errorResponse.message || `Error importing file: ${file.name}`);
                        }

                        // Handle application-level errors (e.g., invalid data format)
                        const data = await response.json();
                        if (data.status !== "success") {
                            throw new Error(data.message || `Error importing file: ${file.name}`);
                        }

                        console.log("File imported successfully:", file.name);
                    })
                    .catch((error) => {
                        console.error("File import error:", error.message);
                        throw error; // Rethrow the error for Promise.allSettled to catch
                    });
            });

            // Wait for all file imports to finish
            Promise.allSettled(promises).then((results) => {
                const errors = results.filter((result) => result.status === "rejected");
                if (errors.length > 0) {
                    const errorMessages = errors.map((error) => error.reason.message).join("\n");
                    window.alert("Error importing files.\nPlease recheck that the template is according to our guidelines.");
                } else {
                    fetchData(); // Refresh data after all files are processed
                    window.alert("All files imported successfully.");
                }
            });

            setDisableSave(false);
        }
    };

    const archiveRow = () => {
        if (!mainTableGridApi || selectedRows.length === 0) {
            alert("No rows selected or table not loaded.");
            return;
        }

        const tableName = pageType === "supplier" ? "suppliers_products" : "design_installations";

        if (archive) {
            // Use duplicate logic to create a new unarchived row
            let completedCount = 0;
            selectedRows.forEach((selectedRow) => {
                fetch(`${config.apiUrl}/duplicate_table_row`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        row_id: selectedRow.id,
                        pageType: pageType,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success) {
                            const duplicatedRow = data.newRow;
                            duplicatedRow.archive = 0;

                            return fetch(`${config.apiUrl}/update_data`, {
                                method: "PUT",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    table_name: tableName,
                                    data: duplicatedRow,
                                    condition: `id = '${duplicatedRow.id}'`,
                                }),
                            });
                        } else {
                            throw new Error("Error duplicating row.");
                        }
                    })
                    .then((updateResponse) => {
                        if (!updateResponse.ok) throw new Error("Network response was not ok: " + updateResponse.statusText);
                        return updateResponse.json();
                    })
                    .then((updateData) => {
                        if (updateData.status === "success") {
                            completedCount++;
                            if (completedCount === selectedRows.length) {
                                fetchData(); // Refresh the table data
                            }
                        }
                    })
                    .catch((error) => console.error("Error unarchiving data:", error));
            });
        } else if (isArivuLibrary) {
            // Duplicate rows and set 'user_id' to 'userId'
            let completedCount = 0;
            selectedRows.forEach((selectedRow) => {
                fetch(`${config.apiUrl}/duplicate_table_row`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        row_id: selectedRow.id,
                        pageType: pageType,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success) {
                            const duplicatedRowId = data.new_row_id;

                            // Fetch the duplicated row from its id
                            return fetch(`${config.apiUrl}/fetch_data`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    table_name: tableName,
                                    condition: `id = '${duplicatedRowId}'`, // Use the duplicated row ID to fetch the specific row
                                }),
                            });
                        } else {
                            throw new Error("Error duplicating row.");
                        }
                    })
                    .then((response) => {
                        if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                        return response.json();
                    })
                    .then((rowData) => {
                        if (rowData && rowData.length > 0) {
                            const duplicatedRow = rowData[0]; // Assuming the row is returned as an array
                            console.log("Duplicated row:", duplicatedRow);

                            duplicatedRow.user_id = userId; // Set 'user_id' to 'userId'

                            return fetch(`${config.apiUrl}/update_data`, {
                                method: "PUT",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    table_name: tableName,
                                    data: duplicatedRow,
                                    condition: `id = '${duplicatedRow.id}'`,
                                }),
                            });
                        } else {
                            throw new Error("Duplicated row not found.");
                        }
                    })
                    .then((updateResponse) => {
                        if (!updateResponse.ok) throw new Error("Network response was not ok: " + updateResponse.statusText);
                        return updateResponse.json();
                    })
                    .then((updateData) => {
                        if (updateData.status === "success") {
                            completedCount++;
                            if (completedCount === selectedRows.length) {
                                fetchData(); // Refresh the table data
                                alert("Successfully copied to your main table");
                            }
                        }
                    })
                    .catch((error) => console.error("Error duplicating rows:", error));
            });
        } else {
            // Regular archive functionality
            const selectedData = mainTableGridApi.getSelectedRows();
            let completedCount = 0;

            selectedData.forEach((row) => {
                fetch(`${config.apiUrl}/update_data`, {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        table_name: tableName,
                        data: { archive: 1 },
                        condition: `id = '${row.id}'`,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                        return response.json();
                    })
                    .then((data) => {
                        if (data.status === "success") {
                            mainTableGridApi.applyTransaction({ remove: [row] });
                            completedCount++;
                            if (completedCount === selectedData.length) {
                                fetchData();
                                alert("Archiving completed successfully.");
                            }
                        }
                    })
                    .catch((error) => console.error("Error archiving data:", error));
            });
        }
    };


    function ConfirmModal({ isOpen, onClose, onConfirm }) {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <button className="modal-close-button" onClick={onClose}>
                        ×
                    </button>
                    <div className="modal-header">{selectedText.confirmDeletion}</div>
                    <div className="modal-body">
                        <p>{selectedText.confirmMessage}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-button modal-confirm-button" onClick={onConfirm}>
                            {selectedText.confirm}
                        </button>
                        <button className="modal-button" onClick={onClose}>
                            {selectedText.cancel}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button
                    onClick={addRow}
                    title="Add a new row in the table"
                    disabled={archive || (!isAdmin && isArivuLibrary)}>
                    <Icon icon="ic:round-plus" style={{ fontSize: "16px" }} />
                </button>
                <button
                    onClick={duplicateRow}
                    disabled={archive || selectedRows.length !== 1 || (!isAdmin && isArivuLibrary)}
                    title="Create a copy of selected row">
                    <Icon icon="ion:duplicate" style={{ fontSize: "16px" }} />
                </button>
                <button
                    onClick={handleOpenConfirmModal}
                    disabled={selectedRows.length === 0 || (!isAdmin && isArivuLibrary)}
                    title="Delete selected rows"
                    className="delete-button">
                    <Icon icon="ic:round-minus" style={{ fontSize: "16px" }} />
                </button>
                {(pageType === "supplier" || pageType === "utilizer") && (
                    <button
                        onClick={archiveRow}
                        disabled={selectedRows.length === 0}
                        title="Archive selected rows"
                        className="archive-button">
                        {isArivuLibrary ? (
                            <Icon icon="mdi:arrow-top-right-bold-box-outline" style={{ fontSize: "16px" }} />
                        ) : archive ? (
                            <Icon icon="mdi:archive-arrow-up-outline" style={{ fontSize: "16px" }} />
                        ) : (
                            <Icon icon="mdi:archive" style={{ fontSize: "16px" }} />
                        )}
                    </button>
                )}
            </div>
            <div className="button-container-right">
                <input
                    type="file"
                    accept=".csv"
                    multiple
                    onChange={handleImport}
                    style={{ display: "none" }}
                    id="import-csv" />
                <Tooltip message="Make sure to save new values before importing">
                    <button
                        onClick={() => document.getElementById("import-csv").click()}
                        style={{ fontSize: "16px" }}
                        title="Import template"
                        disabled={archive || (!isAdmin && isArivuLibrary)}>
                        <Icon icon="carbon:document-import" />
                    </button>
                </Tooltip>
                <button
                    onClick={handleExport}
                    disabled={archive || selectedRows.length === 0}
                    style={{ fontSize: "16px" }}
                    title="Export template">
                    <Icon icon="carbon:document-export" />
                </button>
                <button
                    onClick={saveTable}
                    disabled={archive || disableSave || selectedRows.length === 0 || (!isAdmin && isArivuLibrary)}
                    title="Save template">
                    <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
                </button>
            </div>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={handleCloseConfirmModal}
                onConfirm={deleteSelectedRow} />
        </div>
    );
}

export default memo(TableButton);
