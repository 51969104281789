import React from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, PointElement, LinearScale);

const GwpVsMciChart = ({ mciData, gwpData }) => {
  
  // If mciData only has one value, we can map over gwpData to create multiple points:
  const singleMciValue = parseFloat(mciData[0]);

  const scatterPoints = gwpData.map((gwp) => ({
    x: singleMciValue,          // use the single MCI value as x
    y: parseFloat(gwp),         // each GWP value becomes the y-coordinate
  }));

  const chartData = {
    datasets: [
      {
        label: "Materials",
        data: scatterPoints,
        backgroundColor: "#8884d8",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Percent MCI",
        },
      },
      y: {
        title: {
          display: true,
          text: "Normalized GWP",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = `(${context.raw.x}, ${context.raw.y})`;
            return label;
          },
        },
      },
    },
  };

  return <Scatter data={chartData} options={options} />;
};

export default GwpVsMciChart;
