import React, { useState, useEffect } from "react";
import Grid from "../environment-page-components/Grid";
import CircularityButton from "./CircularityButton";
import config from "../config";
import { translations } from "../app_components/Translation";

function OutputMaterialTable({ data, selectedLanguage }) {
  const [rowData, setRowData] = useState([]);
  const selectedText = translations[selectedLanguage].OutputMaterialTable || {};

  // Function to fetch recycling data based on description and category
  const fetchRecyclingData = async (description, category) => {
    if (!description || !category) return null;

    try {
      const response = await fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          table_name: "emission_info",
          condition: `description = '${description}' AND category = '${category}'`,
        }),
      });

      const data = await response.json();
      return data?.[0]?.recycle || 0; // Default to 0 if no data found
    } catch (error) {
      console.error("Error fetching recycling data:", error);
      return null;
    }
  };

  const fetchAndUpdateData = async () => {
    const updatedData = await Promise.all(
      data.map(async (item) => {
        const {
          id,
          env_id,
          description,
          category,
          normalizedMass = 0, // ✅ Use a default value if missing
          om_recycling,
          om_reuse,
          om_bio_composted,
          om_bio_energy_recovery,
        } = item;

        // Ensure normalizedMass is available
        if (normalizedMass === undefined || normalizedMass === null) {
          console.warn(`Warning: Missing normalizedMass for ${description}. Defaulting to 0.`);
        }

        // Calculate landfill waste
        const calculatedLandfillWaste =
          (normalizedMass *
            (100 - (om_recycling + om_reuse + om_bio_composted + om_bio_energy_recovery))).toFixed(2);

        return {
          id,
          env_id,
          description,
          category,
          normalizedMass, // ✅ Ensure it's included in rowData
          om_recycling,
          om_reuse,
          om_bio_composted,
          om_bio_energy_recovery,
          om_landfill_waste: calculatedLandfillWaste,
        };
      })
    );

    setRowData(updatedData);
  };

  // Load initial data and fetch recycling data
  useEffect(() => {
    if (data && data.length > 0) {
      fetchAndUpdateData();
    }
  }, [data]);

  // Update recycling values from API when button is clicked
  const setDefaultRecycledValues = async () => {
    const updatedData = await Promise.all(
      rowData.map(async (row) => {
        const recycling = await fetchRecyclingData(
          row.description,
          row.category
        );
        return { ...row, om_recycling: recycling };
      })
    );
    setRowData(updatedData);
  };

  const columnDefs = [
    { field: "description", headerName: selectedText.description },
    {
      field: "om_recycling",
      headerName: selectedText.om_recycling,
      editable: true,
    },
    { field: "om_reuse", headerName: selectedText.om_reuse, editable: true },
    {
      field: "om_bio_composted",
      headerName: selectedText.om_bio_composted,
      editable: true,
    },
    {
      field: "om_bio_energy_recovery",
      headerName: selectedText.om_bio_energy_recovery,
      editable: true,
    },
    { field: "om_landfill_waste", headerName: selectedText.om_landfill_waste },
  ];

  const onCellValueChanged = (params) => {
    setRowData((prevData) => {
      return prevData.map((row) => {
        if (row.description === params.data.description) {
          const updatedRow = {
            ...row,
            [params.colDef.field]: parseFloat(params.value) || 0
          };

          // ✅ Ensure normalizedMass is included and has a fallback
          const normalizedMass = row.normalizedMass ?? 0;

          // Recalculate landfill waste dynamically
          updatedRow.om_landfill_waste =
            (normalizedMass *
              (100 -
                ((updatedRow.om_recycling || 0) +
                  (updatedRow.om_reuse || 0) +
                  (updatedRow.om_bio_composted || 0) +
                  (updatedRow.om_bio_energy_recovery || 0)))).toFixed(2);

          return updatedRow;
        }
        return row;
      });
    });
  };



  return (
    <div>
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={() => { }}
        rowSelection="single"
        onSelectionChanged={() => { }}
        getRowStyle={() => { }}
        customMinHeight={200}
        customMaxheight={500}
        onColumnMoved={() => { }}
        onDragStopped={() => { }}
        gridOptions={{ onCellValueChanged }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", }}>
        <button onClick={setDefaultRecycledValues} className={`button-container-output`}
          style={{
            display: 'flex',
            justifyContent: 'right',
            marginTop: '20px',
            marginRight: '10px',
            padding: '8px 16px',
            backgroundColor: '#6ba06c',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#639664'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#6ba06c'} >
          {selectedText.set_default_recycled_values}
        </button>
        <CircularityButton
          data={rowData.map(({ description, category, normalizedMass, ...rest }) => rest)} />

      </div>
    </div>
  );
}

export default OutputMaterialTable;
