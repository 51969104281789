import React, { useState, useEffect } from "react";
import Grid from "../environment-page-components/Grid";
import CircularityButton from "./CircularityButton";
import { translations } from "../app_components/Translation";

function UtilityFactorTable({ data, selectedLanguage }) {
  const [rowData, setRowData] = useState([]);
  const selectedText = translations[selectedLanguage].UtilityFactorTable || {};


  // Load initial data when `data` prop changes
  useEffect(() => {
    if (data && data.length > 0) {
      setRowData(
        data.map((item) => {
          const uf_calculations = calculateUF(item);
          return {
            id: item.id,
            env_id: item.env_id,
            description: item.description,
            uf_product_life: item.uf_product_life, // Use original name
            uf_industry_avg_life: item.uf_industry_avg_life, // Use original name
            uf_functional_units: item.uf_functional_units, // Use original name
            uf_industry_avg_units: item.uf_industry_avg_units, // Use original name
            uf_calculations, // Calculated value
          };
        })
      );
    }
  }, [data]); // Re-run when `data` changes

  const calculateUF = (item) => {
    return (
      (item.uf_product_life / item.uf_industry_avg_life) *
      (item.uf_functional_units / item.uf_industry_avg_units)
    );
  };

  const columnDefs = [
    { field: "description", headerName: selectedText.description, editable: true },
    {
      field: "uf_product_life",
      headerName: selectedText.productLife,
      editable: true,
    },
    {
      field: "uf_industry_avg_life",
      headerName: selectedText.industryAvgLife,
      editable: true,
    },
    {
      field: "uf_functional_units",
      headerName: selectedText.functionalUnits,
      editable: true,
    },
    {
      field: "uf_industry_avg_units",
      headerName: selectedText.industryAvgUnits,
      editable: true,
    },
    { field: "uf_calculations", headerName: selectedText.calculations, editable: false },
  ];

  const onCellValueChanged = (params) => {
    const { data, colDef, value } = params;

    // Update the row data with the new value
    setRowData((prevData) =>
      prevData.map((row) =>
        row.id === data.id ? { ...row, [colDef.field]: value } : row
      )
    );

    // Recalculate the 'uf_calculations' field for the updated row
    setRowData((prevData) =>
      prevData.map((row) =>
        row.id === data.id ? { ...row, uf_calculations: calculateUF(row) } : row
      )
    );
  };

  return (
    <div>
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={() => { }}
        rowSelection="single"
        onSelectionChanged={() => { }}
        getRowStyle={() => { }}
        customMinHeight={200}
        customMaxheight={500}
        onColumnMoved={() => { }}
        onDragStopped={() => { }}
        gridOptions={{ onCellValueChanged }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", }}>
      <CircularityButton data={rowData} />
      </div>
    </div>
  );
}

export default UtilityFactorTable;
