import React, { useState, useEffect, useMemo } from "react";
import "../style/components-style/ProcessTable.css";
import config from "../config";
import Grid from "./Grid";
import ProcessEnvFactorButton from "../environment-page-components/ProcessEnvFactorButton";
import { Icon } from "@iconify/react";
import { translations } from "../app_components/Translation";
import eventBus from "../eventBus";

function ProcessTable({
  setSelectedProcessData,
  setProcessData,
  selectedRow,
  setProcessTableGridApi,
  isSupplier,
  selectedLanguage,
  setAllProcessRows,
  archive,
  isArivuLibrary,
  isAdmin,
  isStandardSuppliersLibrary,
}) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [processRowData, setProcessRowData] = useState([]);
  const [sortedProcessRowData, setSortedProcessRowData] = useState([]); // New state for sorted data
  const [isOpen, setIsOpen] = useState(true);
  const [selectedProcessesRows, setSelectedProcessesRows] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state to show spinner
  const selectedText = translations[selectedLanguage].processTable;
  const [selectedTab, setSelectedTab] = useState(selectedText.all);

  const isCollaboratorIdEmpty =
    selectedRow?.collaborator_id == null ||
    selectedRow?.collaborator_id === "" ||
    selectedRow?.collaborator_id === "N/A";
  const isEditable =
    !archive && isCollaboratorIdEmpty && !(isArivuLibrary && !isAdmin);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setProcessTableGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const refreshProcessData = () => {
    if (!selectedRow || !selectedRow["id"]) {
      setProcessRowData([]);
      setSortedProcessRowData([]); // Clear sorted data as well
      setSelectedProcessesRows([]); // Clear selected process rows
      if (gridApi) {
        gridApi.setRowData([]); // Clear the grid
      }
      setLoading(false); // Stop the spinner if there's no selected row
      return;
    }

    const mainTableID = selectedRow["id"];

    setLoading(true); // Show loading spinner during the refresh

    // Create a new AbortController instance
    const controller = new AbortController();
    const signal = controller.signal;

    fetch(`${config.apiUrl}/api/get-processes-by-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productOrDesignId: mainTableID,
      }),
      signal, // Pass the signal to the fetch request
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const sortedData = sortData(data); // Sort the data immediately after fetching
        setProcessRowData(sortedData);
        setAllProcessRows(sortedData);
        setProcessData(sortedData);
        setSortedProcessRowData(sortedData); // Update sortedProcessRowData

        // Update the selected processes rows if needed
        setSelectedProcessesRows([]); // Reset selection

        // Update the grid with new data
        if (gridApi) {
          gridApi.setRowData(sortedData); // Update the grid with sorted row data
        }

        setLoading(false); // Stop the spinner once data is refreshed
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error.message);
        }
        setLoading(false); // Stop the spinner if an error occurs
      });

    // Store the controller to abort the request if needed
    return controller;
  };

  // Fetch environmental factors after row selection
  const fetchSelectedRowEnvFactor = (selectedData) => {
    const processIDs = selectedData.map((row) => row.id);

    const fetchPromises = processIDs.map((processID) => {
      const url = new URL(`${config.apiUrl}/api/get-env-factor-by-process-id`);
      url.searchParams.append("process_id", processID);

      return fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      });
    });

    Promise.all(fetchPromises)
      .then((results) => {
        const processesWithEnvFactor = new Set(
          results.flat().map((envFactor) => envFactor.process_id)
        );

        const updatedSelectedData = selectedData.map((item) => ({
          ...item,
          hasEnvFactor: processesWithEnvFactor.has(item.id),
        }));

        setSelectedProcessesRows(updatedSelectedData); // Update the selected rows with env factors
        setSelectedProcessData(updatedSelectedData); // Set selected rows with env factors in parent state
      })
      .catch((error) => {
        console.error("Error fetching env factor data:", error.message);
      });
  };

  useEffect(() => {
    if (!gridApi) return;

    const onSelectionChanged = () => {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      fetchSelectedRowEnvFactor(selectedData); // Fetch env factors for selected rows
    };

    gridApi.addEventListener("selectionChanged", onSelectionChanged);
    return () => {
      gridApi.removeEventListener("selectionChanged", onSelectionChanged);
    };
  }, [gridApi]);

  useEffect(() => {
    if (!selectedRow) return;

    // Add a one-second delay before calling refreshProcessData
    const delayTimeout = setTimeout(() => {
      refreshProcessData();
    }, 1000); // 1-second delay before switching rows

    // Cleanup the timeout if the component is unmounted or the selectedRow changes before the delay is finished
    return () => clearTimeout(delayTimeout);
  }, [selectedRow]);

  useEffect(() => {
    if (!selectedRow) {
      setProcessRowData([]);
      setSortedProcessRowData([]);
      setSelectedProcessesRows([]);
      if (gridApi) {
        gridApi.setRowData([]); // Clear the grid
      }
    }
  }, [selectedRow, gridApi]);

  function formatFloatValue(value, decimalPlaces) {
    return parseFloat(value).toFixed(decimalPlaces);
  }

  const sortData = (dataToSort) => {
    const sortOrder = {
      A1: 1,
      A2: 2,
      A3: 3,
      A4: 4,
      A5: 5,
      B1: 6,
      B2: 7,
      B3: 8,
      B4: 9,
      B5: 10,
      C1: 11,
      C2: 12,
      C3: 13,
      C4: 14,
      D: 15,
    };

    const sortedData = [...dataToSort].sort((a, b) => {
      const stageA = a.lca_stage;
      const stageB = b.lca_stage;

      if (sortOrder[stageA] !== undefined && sortOrder[stageB] !== undefined) {
        return sortOrder[stageA] - sortOrder[stageB];
      }

      if (sortOrder[stageA] !== undefined) return -1;
      if (sortOrder[stageB] !== undefined) return 1;

      return stageA.localeCompare(stageB);
    });

    return sortedData;
  };

  useEffect(() => {
    if (!processRowData || processRowData.length === 0) return;

    fetchEnvFactorData().then((updatedRowData) => {
      const tab = filterTabs.find((tab) => tab.label === selectedTab);
      if (!tab) {
        console.error(`No filter found for tab: ${selectedTab}`);
        setLoading(false);
        return;
      }

      // Apply the selected tab's filter
      let filteredData = updatedRowData.filter(tab.filter);

      // Sort the filtered data
      const sortedData = sortData(filteredData);

      // Set the sorted data to the grid and end loading
      setSortedProcessRowData(sortedData);

      // Force the grid to refresh with the sorted and filtered data
      if (gridApi) {
        gridApi.setRowData(sortedData);
      }

      setLoading(false); // End loading after sorting is done
    });
  }, [processRowData, selectedTab, gridApi]);

  const fetchEnvFactorData = () => {
    if (!processRowData || processRowData.length === 0)
      return Promise.resolve([]);

    const processIDs = processRowData.map((process) => process["id"]);
    const fetchPromises = processIDs.map((processID) => {
      const url = new URL(`${config.apiUrl}/api/get-env-factor-by-process-id`);
      url.searchParams.append("process_id", processID);

      return fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      });
    });

    return Promise.all(fetchPromises)
      .then((results) => {
        const processesWithEnvFactor = new Set(
          results.flat().map((envFactor) => envFactor.process_id)
        );

        const updatedRowData = processRowData.map((item) => ({
          ...item,
          hasEnvFactor: processesWithEnvFactor.has(item.id),
        }));

        // Ensure the grid is refreshed with updated data
        if (gridApi) {
          gridApi.setRowData(updatedRowData); // This forces the grid to update
        }

        return updatedRowData;
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        return [];
      });
  };

  const filterTabs = isSupplier
    ? [
      {
        label: selectedText.product,
        filter: (item) =>
          ["A1", "A2", "A3"].some((prefix) =>
            item.lca_stage.startsWith(prefix)
          ),
      }, // All restricted to A1-A3 for suppliers
      { label: "A1", filter: (item) => item.lca_stage.startsWith("A1") }, // Filter by A1 for suppliers
      { label: "A2", filter: (item) => item.lca_stage.startsWith("A2") }, // Filter by A2 for suppliers
      { label: "A3", filter: (item) => item.lca_stage.startsWith("A3") }, // Filter by A3 for suppliers
    ]
    : [
      { label: selectedText.all, filter: () => true }, // All stages for non-suppliers
      {
        label: selectedText.product,
        filter: (item) =>
          ["A1", "A2", "A3"].some((prefix) =>
            item.lca_stage.startsWith(prefix)
          ),
      },
      {
        label: selectedText.construction,
        filter: (item) =>
          ["A4", "A5"].some((prefix) => item.lca_stage.startsWith(prefix)),
      },
      {
        label: selectedText.use,
        filter: (item) => item.lca_stage.startsWith("B"),
      },
      {
        label: selectedText.endOfLife,
        filter: (item) => item.lca_stage.startsWith("C"),
      },
      {
        label: selectedText.benefits,
        filter: (item) => item.lca_stage.startsWith("D"),
      },
    ];

  const lcaStageValues = isSupplier
    ? [
      "A1: Raw Material Acquisition",
      "A2: Transport to Factory",
      "A3: Manufacturing",
    ]
    : [
      "A1: Raw Material Acquisition",
      "A2: Transport to Factory",
      "A3: Manufacturing",
      "A4: Transport to Site",
      "A5: Construction-Installation",
      "B1: Use",
      "B2: Maintenance",
      "B3: Repair",
      "B4: Replacement",
      "B5: Refurbishment",
      "C1: Deconstuction / Demolition",
      "C2: Transport to Waste / Disposal",
      "C3: Waste Processing",
      "C4: Disposal of Waste",
      "D: Reuse-Recovery-Recycling Potential",
    ];

  const processGridProps = {
    columnDefs: [
      { field: "id", hide: true },
      { field: "product_id", hide: true },
      { field: "design_id", hide: true },
      {
        headerName: selectedText.headers.lcaStage,
        field: "lca_stage",
        cellEditor: "agSelectCellEditor",
        editable: isEditable,
        cellEditorParams: {
          values: lcaStageValues,
        },
        minWidth: 210,
        width: 240,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: selectedText.headers.process,
        field: "name",
        cellEditor: "agTextCellEditor",
        editable: isEditable,
        minWidth: 130,
        width: 200,
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: selectedText.headers.biogenic_gwp,
        field: "biogenic_gwp",
        editable: (params) => isEditable && !params.data.hasEnvFactor,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 120,
        maxWidth: 200,
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
      {
        headerName: selectedText.headers.gwp,
        field: "gwp",
        editable: (params) => isEditable && !params.data.hasEnvFactor,
        valueFormatter: (params) => formatFloatValue(params.value, 2),
        minWidth: 100,
        maxWidth: 200,
        cellStyle: { textAlign: "right" },
        headerClass: "ag-right-aligned-header",
      },
    ],
    rowSelection: "multiple",
    rowData: sortedProcessRowData, // Render the sorted data
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "filters",
    },
    onGridReady,
  };

  const toggleContainer = () => setIsOpen(!isOpen);

  // Function to calculate the total GWP
  const calculateTotalGWP = useMemo(() => {
    const dataToSum =
      selectedProcessesRows.length > 0
        ? selectedProcessesRows
        : sortedProcessRowData;

    return dataToSum
      .filter((row) => !row["lca_stage"].startsWith("D"))
      .reduce((total, row) => {
        const gwpValue = parseFloat(row.gwp);
        return !isNaN(gwpValue) ? total + gwpValue : total;
      }, 0)
      .toFixed(2);
  }, [selectedProcessesRows, sortedProcessRowData]);

  useEffect(() => {
    const activeButton = document.querySelector(".filter-bar button.active");
    const indicator = document.querySelector(".filter-bar .active-indicator");
    const filterBar = document.querySelector(".filter-bar");

    if (activeButton && indicator && filterBar) {
      const buttonRect = activeButton.getBoundingClientRect();
      const barRect = filterBar.getBoundingClientRect();

      // Get the scroll offset of the filter bar
      const scrollOffset = filterBar.scrollLeft;

      // Set the width of the indicator to match the active button
      indicator.style.width = `${buttonRect.width}px`;

      // Adjust the left position based on scroll offset
      indicator.style.left = `${buttonRect.left - barRect.left + scrollOffset
        }px`;
    }
  }, [selectedTab]);

  useEffect(() => {
    const filterBar = document.querySelector(".filter-bar");

    const handleScroll = () => {
      const activeButton = document.querySelector(".filter-bar button.active");
      const indicator = document.querySelector(".filter-bar .active-indicator");

      if (activeButton && indicator && filterBar) {
        const buttonRect = activeButton.getBoundingClientRect();
        const barRect = filterBar.getBoundingClientRect();

        // Adjust based on scroll offset
        const scrollOffset = filterBar.scrollLeft;

        indicator.style.width = `${buttonRect.width}px`;
        indicator.style.left = `${buttonRect.left - barRect.left + scrollOffset
          }px`;
      }
    };

    // Listen for scroll events on the filter bar
    filterBar.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      filterBar.removeEventListener("scroll", handleScroll);
    };
  }, [selectedTab]);

  useEffect(() => {
    // Listen for the 'dataSaved' event and trigger a delayed refresh
    const handleDataSaved = () => {
      setLoading(true); // Show the spinner when the event is received

      // Delay the refresh by 2 seconds to allow the backend to process the save
      setTimeout(() => {
        refreshProcessData(); // Refresh the process data after the delay
      }, 5000);
    };

    eventBus.on("dataSaved", handleDataSaved);

    return () => {
      eventBus.off("dataSaved", handleDataSaved); // Clean up the listener when the component unmounts
    };
  }, [gridApi]);

  const totalBiogenicGwp = useMemo(() => {
    const dataToSum =
      selectedProcessesRows.length > 0 ? selectedProcessesRows : processRowData;

    return dataToSum.reduce(
      (sum, row) => sum + parseFloat(row["biogenic_gwp"] || 0),
      0
    );
  }, [selectedProcessesRows, processRowData]);

  return (
    <div
      className="process-table"
      style={{
        backgroundColor:
          archive || isArivuLibrary || isStandardSuppliersLibrary
            ? "#faf4e8"
            : "white",
      }}
    >
      <div className="container-header-process">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() =>
          (document.querySelector(
            ".container-header-process h2"
          ).style.color = "grey")
          } // Hover color
          onMouseLeave={() =>
          (document.querySelector(
            ".container-header-process h2"
          ).style.color = "black")
          } // Default color
          style={{ cursor: "pointer" }}
        >
          {selectedText.process}
        </h2>
      </div>
      {isOpen && (
        <>
          <div className="filter-bar">
            {filterTabs.map((tab, index) => (
              <button
                key={tab.label}
                onClick={() => {
                  setSelectedTab(tab.label);
                  document.documentElement.style.setProperty(
                    "--active-index",
                    index
                  ); // Update active index
                }}
                className={selectedTab === tab.label ? "active" : ""}
              >
                {tab.label}
              </button>
            ))}
            <div className="active-indicator"></div>{" "}
            {/* New element for the active indicator */}
          </div>

          {loading ? (
            <div className="spinner-container">
              <div className="spinner"></div>{" "}
              {/* Display the spinner when loading */}
            </div>
          ) : (
            <div className="grid-container">
              <div className="grid-wrapper">
                <Grid {...processGridProps} />
              </div>
              <div className="gwp-total">
                <span className="gwp-total-text">
                  {" "}
                  {selectedText.biogenicTotal} {totalBiogenicGwp.toFixed(2)}
                </span>
                <span className="gwp-divider"> | </span>
                <span className="gwp-biogenic-total-text">
                  {" "}
                  {selectedText.fossilTotal} {calculateTotalGWP}
                </span>
              </div>

              <div className="process-button-container">
                <ProcessEnvFactorButton
                  selectedRows={selectedRow}
                  selectedProcessesRows={selectedProcessesRows}
                  tableGridApi={gridApi}
                  isForProcess={true}
                  product={isSupplier ? selectedRow : null}
                  design={!isSupplier ? selectedRow : null}
                  process={null}
                  refreshData={refreshProcessData}
                  selectedLanguage={selectedLanguage}
                  isEditable={
                    selectedRow &&
                    (!selectedRow.collaborator_id ||
                      selectedRow.collaborator_id === "N/A" ||
                      selectedRow.collaborator_id === "" ||
                      selectedRow.collaborator_id === null)
                  }
                  // isAddButtonDisabled={!selectedRow || selectedRow.collaborator_id}
                  archive={archive}
                  isAdmin={isAdmin}
                  isArivuLibrary={isArivuLibrary}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ProcessTable;
