import React, { useState, useEffect } from "react";
import Grid from "../environment-page-components/Grid";
import { translations } from "../app_components/Translation";

function MciTable({ fetchedData, selectedLanguage, setChartData }) {
  const [rowData, setRowData] = useState([]);
  const [quantitySum, setQuantitySum] = useState(0);

  // Calculate LFI, MCI, and %MCI for each row
  useEffect(() => {
    if (fetchedData && fetchedData.length > 0) {
      const updatedRows = fetchedData.map((row) => {
        // LFI Calculation
        const LFI = (
          ((row.im_mass_virgin_material + row.eol_unrecoverable_waste) /
            (2 * row.normalizedMass +
              0.5 * (row.eol_recycling_waste - row.eol_feedstock_waste))
          ) / 100).toFixed(2);
        
        // MCI Calculation
        const MCI = ((100 - (LFI * 100) * (0.9 / row.uf_calculations)) / 100).toFixed(2);
        
        // %MCI Calculation
        const percentMCI = (((MCI * 100) - 10) / 0.9).toFixed(1);
        
        return {
          ...row,
          LFI,
          MCI,
          percentMCI,
        };
      });
      setRowData(updatedRows);

      // Calculate quantitySum
      const totalQuantitySum = fetchedData.reduce(
        (sum, row) => sum + row.quantity,
        0
      );
      
      setQuantitySum(totalQuantitySum);

      // Send %MCI values to parent
      const percentMCIValues = updatedRows.map((row) => row.percentMCI);
      setChartData(percentMCIValues);
    }
  }, [fetchedData, setChartData]);

  const calculateProductMCI = () => {
    if (quantitySum === 0) {
      console.log("quantitySum is 0, returning Product MCI as 0");
      return 0; // Avoid division by zero
    }

    // Log the quantities and MCI for each row
    rowData.forEach((row) => {
      console.log(`Row Quantity: ${row.quantity}, MCI: ${row.MCI}`);
    });

    console.log("Total Quantity Sum:", quantitySum);

    // Calculate Product MCI
    const productMCI =
      (rowData.reduce((sum, row) => sum + row.quantity * (row.MCI * 100), 0) /
      quantitySum).toFixed(2);
    return productMCI;
  };

  // Columns for the grid
  const columnDefs = [
    { headerName: "Description", field: "description" },
    { headerName: "LFI", field: "LFI" },
    { headerName: "MCI", field: "MCI" },
    { headerName: "Product MCI", valueGetter: calculateProductMCI },
    { headerName: "% MCI", field: "percentMCI" },
  ];

  return (
    <Grid
      columnDefs={columnDefs}
      rowData={rowData}
    />
  );
}

export default MciTable;
