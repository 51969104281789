import React, { useState, useEffect } from "react";
import Grid from "../environment-page-components/Grid";
import CircularityButton from "./CircularityButton";
import { translations } from "../app_components/Translation";

function EndOfLifeTreatmentTable({ data, selectedLanguage }) {
  console.log("eol data", data); // Debugging
  const [rowData, setRowData] = useState([]);
  const selectedText =
    translations[selectedLanguage].EndOfLifeTreatmentTable || {};

  // Load initial data when `data` prop changes
  useEffect(() => {
    if (data && data.length > 0) {
      const updatedData = data.map((item) => {
        const {
          id,
          env_id,
          description,
          category,
          eol_recycling_efficiency,
          eol_feedstock_efficiency,
          normalizedMass,
          om_recycling, // Using om_recycling directly
          om_landfill_waste, // Using om_landfill_waste directly
          im_recycled_content,
        } = item;

        // Ensure fallback for undefined or null values
        const eolRecyclingEfficiency = eol_recycling_efficiency ?? 0;
        const eolFeedstockEfficiency = eol_feedstock_efficiency ?? 0;
        const mass = normalizedMass ?? 0;
        const recycling = om_recycling ?? 0;
        const landfill = om_landfill_waste ?? 0;

        // Calculate eol_recycling_waste (avoid division by zero)
        const eol_recycling_waste = eolRecyclingEfficiency === 0
          ? 0
          : (mass * (100 - eolRecyclingEfficiency) * (im_recycled_content / eolRecyclingEfficiency));

        // Calculate eol_feedstock_waste
        const eol_feedstock_waste = (mass * (100 - eolFeedstockEfficiency) * recycling);

        // Calculate eol_unrecoverable_waste
        const eol_unrecoverable_waste = landfill + 0.5 * (eol_recycling_waste + eol_feedstock_waste);

        return {
          id,
          env_id,
          description,
          category,
          eol_recycling_efficiency: eolRecyclingEfficiency,
          eol_feedstock_efficiency: eolFeedstockEfficiency,
          eol_recycling_waste: eol_recycling_waste.toFixed(2),
          eol_feedstock_waste: eol_feedstock_waste.toFixed(2),
          eol_unrecoverable_waste: eol_unrecoverable_waste.toFixed(2),
          // Keep original values for recalculation later
          normalizedMass: mass,
          om_recycling: recycling,
          om_landfill_waste: landfill,
        };
      });
      setRowData(updatedData);
    }
  }, [data]); // Runs whenever `data` changes

  const columnDefs = [
    { field: "description", headerName: selectedText.description },
    {
      field: "eol_recycling_efficiency",
      headerName: selectedText.eol_recycling_efficiency,
      editable: true,
    },
    {
      field: "eol_feedstock_efficiency",
      headerName: selectedText.eol_feedstock_efficiency,
      editable: true,
    },
    {
      field: "eol_recycling_waste",
      headerName: selectedText.eol_recycling_waste,
    },
    {
      field: "eol_feedstock_waste",
      headerName: selectedText.eol_feedstock_waste,
    },
    {
      field: "eol_unrecoverable_waste",
      headerName: selectedText.eol_unrecoverable_waste,
    },
  ];

  // Dynamically recalculate when cell values change (like OutputMaterialTable)
  const onCellValueChanged = (params) => {
    setRowData((prevData) => {
      return prevData.map((row) => {
        if (row.id === params.data.id) {
          // Update the changed field
          const updatedRow = {
            ...row,
            [params.colDef.field]: parseFloat(params.value) || 0,
          };

          // Extract updated values
          const mass = updatedRow.normalizedMass ?? 0;
          const recycling = updatedRow.om_recycling ?? 0;
          const landfill = updatedRow.om_landfill_waste ?? 0;
          const eolRecyclingEfficiency = updatedRow.eol_recycling_efficiency ?? 0;
          const eolFeedstockEfficiency = updatedRow.eol_feedstock_efficiency ?? 0;

          // Recalculate eol_recycling_waste (avoid dividing by zero)
          const newRecyclingWaste = eolRecyclingEfficiency === 0
            ? 0
            : mass * (100 - eolRecyclingEfficiency) * (recycling / eolRecyclingEfficiency);

          // Recalculate eol_feedstock_waste
          const newFeedstockWaste =
            mass * (100 - eolFeedstockEfficiency) * recycling;

          // Recalculate eol_unrecoverable_waste
          const newUnrecoverableWaste =
            landfill + 0.5 * (newRecyclingWaste + newFeedstockWaste);

          updatedRow.eol_recycling_waste = newRecyclingWaste.toFixed(2);
          updatedRow.eol_feedstock_waste = newFeedstockWaste.toFixed(2);
          updatedRow.eol_unrecoverable_waste = newUnrecoverableWaste.toFixed(2);

          return updatedRow;
        }
        return row;
      });
    });
  };

  // Filter out description, category, and eol_recycling from the data before passing to CircularityButton
  const filteredRowData = rowData.map(
    ({ description, category, om_recycling, normalizedMass, ...rest }) => rest
  );

  return (
    <div>
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={() => { }}
        rowSelection="single"
        onSelectionChanged={() => { }}
        getRowStyle={() => { }}
        customMinHeight={200}
        customMaxheight={500}
        onColumnMoved={() => { }}
        onDragStopped={() => { }}
        gridOptions={{ onCellValueChanged }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CircularityButton data={filteredRowData} />
      </div>
    </div>
  );
}

export default EndOfLifeTreatmentTable;
