export const translations = {
  EN: {
    contactInfo: "Emailus@vrtta.green | +1 (438) 882-5385",
    copyrightInfo: "Copyright © 2025",
    arivu: "ARIVU | ABCD [version 2025]",
    vrtta: "VRTTA Green Solutions Inc. All rights reserved.",
    product: "Products / Services",
    fleetPerformanceTitle: "Fleet Performance",
    organizational: "Organizational",
    netZeroTitle: "Net Zero",
    circularityTitle: "Circularity",
    supplier: "Supplier",
    utilizer: "Utilizer",
    generateReport: "Generate Report",
    supply_chain: "Value Chain",
    organization: "Organization",
    corporate: "Corporate",
    comparisonTitle: "Comparison",
    reuseTitle: "Reuse",
    description: "",
    table: {
      product: "Products / Services",
      supplier: "Supplier",
      leedCategory: "LEED Category",
      subCategory: "Sub Category",
      year: "Year",
      quantity: "Quantity",
      gwpTotal: "GWP Total (kg CO₂e)",
      gwpTotal_kg: "GWP Total (kg CO₂e)",
      gwpTotal_ton: "GWP Total (tonne CO₂e)",
      cost: "Cost($)",
      costPerGwp: "Cost / GWP",
      designInstallation: "Design Installation",
      location: "Location",
      tableTitle: "Products / Services",
      designInstallationsTitle: "Design Installations",
      displayAdditionalInfo: "Display additional information",
      collaborate: "Collaborate",
      trackView: "Global View",
      structureView: "Structural View",
      documentViewer: "Document Viewer",
      toggleDetails: "Details",
      organization: "Organization",
      fleet: "Fleet",
      fleetTitle: "Fleet Performance",
      vehicleType: "Vehicle Type",
      trailerType: "Trailer Type",
      fleetType: "Fleet Type",
      fuelConsumed: "Fuel Consumed",
      distanceTravelled: "Distance Travelled",
      distancePerGwp: "Distance / GWP",
      organizationTitle: "Organizations",
      valueChainTableTitle: "Products/Services",
      product_service: "Product/Service",
      corporate: "Corporate",
      corporateTitle: "Corporates",
      role: "Role",
      name: "Name",
      orgType: "Control Approach",
      percentage: "Percentage",
    },
    buttons: {
      addRow: "Add Row",
      duplicate: "Duplicate",
      delete: "Delete",
      importCsv: "Import Template",
      exportCsv: "Export Template",
      save: "Save",
      confirmDeletion: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete the selected rows?",
      confirm: "Delete",
      cancel: "Cancel",
    },
    comparison: {
      supplier: "Supplier",
      utilizer: "Utilizer",
      supply_chain: "Value Chain",
      organization: "Organization",
      inventoryAsOf: "Inventory as of",
      selectComparisonType: "Select Comparison Type",
      comparisonBasedOn: "Comparison Based On",
      selectValuesToCompare: "Select Values to Compare",
      apply: "Apply",
      environmentalFootprint: "Environmental Footprint",
      footprintSummary: "Footprint Summary",
      percentageDifference: "Percentage Difference",
      gwp: "GWP [kg CO₂e]",
      phases: "Phases",
      productPhase: "Product",
      constructionPhase: "Construction",
      usePhase: "Use",
      endOfLifePhase: "End-of-life",
      benefitsPhase: "Benefits",
      scopes: "Scopes",
      scope1: "Scope1",
      scope2: "Scope2",
      scope3: "Scope3",
      scope4: "Scope4",
      total: "Total",
      products: "Products",
      organizations: "Organizations",
      percentageDifferenceChart: "Percentage Difference (%)",
      lessThan: "% less than",
      moreThan: "% more than",
      value: "Value",
      allUnits: "All units are calculated in kg CO₂e",
    },
    netZero: {
      ghgTitle: 'Yearly Based GHG',
      scopes: 'Scope or scope 3 category',
      ghgGraphTitle: 'Yearly Based GHG Graph',
      netZeroToolTitle: 'Net Zero Tool',
      generateNetZeroReport: 'Generate NetZero Report',
    },
    phaseCard: {
      average: "Average",
    },
    reuse: {
      product: "Product",
      donorCredit: "Donor Avoided Emissions (kg CO₂e)",
      avoidedEmissions: "Avoided Emissions",
      receiverCredit: "Receiver Avoided Emissions (kg CO₂e)",
      designInstallation: "Design Installation",
      selectAtLeastOne: "Please select at least one product or design installation.",
      donorCredits: "donor avoided emissions",
      receiverCredits: "receiver avoided emissions",
      quantity: "Quantity",
      gridNotReady: "Grid is not ready",
      csvFileName: "reuse_data.csv",
      reuseType: "Reuse Type",
      supplier: "Supplier",
      utilizer: "Utilizer",
      products: "Avoided Emissions",
      designInstallations: "Design Installations",
      exportAsCsv: "Export as CSV",
      loadingData: "Loading data...",
      generatedGraph: "Avoided Emissions Graph",
      credits: "Avoided Emissions",
      totalDonorCredits: "Total Donor Avoided Emissions",
      totalReceiverCredits: "Total Receiver Avoided Emissions",
    },
    login: {
      username: "Username",
      password: "Password",
      invalidCredentials: "Invalid username or password",
      enterUsername: "Enter your username",
      enterPassword: "Enter your password",
      login: "Login",
      welcome: "Welcome",
    },
    register: {
      username: "Username",
      password: "Password",
      recheckPassword: "Recheck Password",
      companyName: "Company Name",
      address: "Address",
      phoneNumber: "Phone Number",
      email: "Email",
      latitude: "Latitude",
      longitude: "Longitude",
      register: "Register",
      invalidCredentials: "Invalid username or password",
      passwordsDoNotMatch: "Passwords do not match",
      enterUsername: "Enter your username",
      enterPassword: "Enter your password",
      enterRecheckPassword: "Re-enter your password",
      enterCompanyName: "Enter your company name",
      enterAddress: "Enter your address",
      enterPhoneNumber: "Enter your phone number",
      enterEmail: "Enter your email",
      enterLatitude: "Enter your latitude",
      enterLongitude: "Enter your longitude",
    },
    termsAndConditions: {
      termsHeader: "Terms and Conditions",
      termsContent: ["These are the terms and conditions...", "More details about the terms and conditions..."],
      decline: "Decline",
      accept: "Accept",
    },
    lifeCycleAssessment: {
      title: "Life Cycle Assessment",
      orgTitle: "GHG Emission Assessment",
      generalInformation: "General Information",
      referencePCR: "Reference PCR",
      declaredUnit: "Declared Unit",
      scope: "Scope",
      lcaSoftware: "LCA Software",
      epdUrl: "EPD URL",
      lciDatabase: "LCI Database(s)",
      lciaMethodology: "LCIA Methodology",
      cutOff: "Cut-Off",
      allocationProcedure: "Allocation Procedure",
      primarySources: "Primary Sources of LCI Data",
      interpretation: "Interpretation",
      references: "References / Environmental Factors",
      methodology: "Methodology",
      organizationalBoundaries: "Organizational Boundaries",
      operationalBoundaries: "Operational Boundaries",
      baseYear: "Base Year",
    },
    systemBoundary: {
      supplierTitle: "System Boundary (Cradle To Gate)",
      utilizerTitle: "System Boundary (Cradle To Grave)",
      productStage: "Product stage",
      rawMaterialAcquisition: "Raw Material Acquisition",
      transportToFactory: "Transport to Factory",
      manufacturing: "Manufacturing",
      constructionStage: "Construction stage",
      transportToSite: "Transport to Site",
      constructionInstallation: "Construction-Installation",
      useStage: "Use stage",
      use: "Use",
      maintenance: "Maintenance",
      repair: "Repair",
      replacement: "Replacement",
      refurbishment: "Refurbishment",
      endOfLifeStage: "End-of-life stage",
      deconstructionDemolition: "Deconstruction / Demolition",
      transportToWasteDisposal: "Transport to Waste / Disposal",
      wasteProcessing: "Waste Processing",
      disposalOfWaste: "Disposal of Waste",
      beyondBuildingLifeCycle: "Beyond the building life cycle",
      reuseRecoveryRecyclingPotential: "Reuse-Recovery-Recycling Potential",
    },
    header: {
      supplier: "Supplier",
      utilizer: "Utilizer",
      comparison: "Comparison",
      supply_chain: "Value Chain",
      organization: "Organization",
      corporate: "Corporate",
      reuse: "Reuse",
      circularity: "Circularity",
    },
    sideMenu: {
      support: "Support",
      settings: "Settings",
      profile: "Profile",
    },
    processTable: {
      processes: "Processes",
      lcaStage: "LCA Stage",
      process: "Processes",
      gwp: "GWP Total (kg CO₂e)",
      all: "All",
      product: "Product",
      construction: "Construction",
      use: "Use",
      endOfLife: "End-of-life",
      benefits: "Benefits",
      biogenicTotal: "Total: Biogenic",
      fossilTotal: "Fossil",
      headers: {
        process: "Process",
        gwp: "GWP Total (kg CO₂e)",
        lcaStage: "LCA Stage",
        biogenic_gwp: "Biogenic GWP Total (kg CO₂e)",
      },
    },
    scopeTable: {
      scope: "Emissions",
      all: "All",
      scope1: "Scope 1",
      scope2: "Scope 2",
      scope3: "Scope 3",
      scope4: "Scope 4",
      avoided: "Scope 4 (Avoided Emission)",
      headers: {
        category: "Category",
        description: "Description",
        gwp_kg: "GWP Total (kg CO₂e)",
        gwp_ton: "GWP Total (tonne CO₂e)",
      },
    },
    processEnvFactorButton: {
      addRow: "Add Row",
      deleteSelectedRow: "Delete",
      saveTable: "Save",
    },
    footprint: {
      footprintOverview: "Footprint Overview",
      materialOverview: "Material Overview",
      product: "Product",
      construction: "Construction",
      use: "Use",
      endOfLife: "End-of-life",
      benefits: "Benefits",
      unitsInfo: "All units are calculated in kg",
      unitsInfoCO2e: "All units are calculated in kg CO₂e",
      emissionGraph: "Emission Graph",
      detailsGraph: "Details Graph",
      environmentalFootprint: "Environmental Footprint",
      materialFootprint: "Material Footprint",
      totalWeight: "Total Weight:",
      gwpTotal: "GWP Total:",
      biogenicGwpTotal: "Biogenic GWP Total:",
      environmental: "Environmental",
      material: "Material",
      dropdown: {
        all: "All",
        product: "Product (A1-A3)",
        construction: "Construction (A4-A5)",
        use: "Use (B)",
        endOfLife: "End-of-life (C)",
        benefits: "Benefits (D)",
        rawMaterial: "A1: Raw Material Acquisition",
        transportFactory: "A2: Transport to Factory",
        manufacturing: "A3: Manufacturing",
      },
      scopeDropdown: {
        scope1: "Scope1",
        scope2: "Scope2",
        scope3: "Scope3",
        scope4: "Scope4",
        avoided: "Avoided",
      },
    },
    envFactorTable: {
      envFactors: "Environmental Factors",
      type: "Type",
      category: "Category",
      description: "Description",
      unit: "Unit",
      factor: "Factor",
      quantity: "Quantity",
      uncertainty: "Uncertainty",
      gwpTotal: "GWP Total (kg CO₂e)",
      gwpUnit: "GWP / Unit",
      biogenicTotal: "Total: Biogenic",
      fossilTotal: "Fossil",
    },
    scopeCategoryDetailsTable: {
      scopeDetails: "Details",
      calculationMethod: "Method",
      type: "Type",
      category: "Category",
      description: "Description",
      unit: "Unit",
      factor: "Factor",
      quantity: "Quantity",
      gwpUnit: "GWP/Unit",
      TotalCO2Eq: "Total CO₂e (kg)",
    },
    GHGInfoTable: {
      ghgInfo: "GHG Information",
    },
    profile: {
      loginInfo: "Login Info",
      email: "Email",
      password: "Password",
      userProfile: "User Profile",
      company: "Company",
      address: "Address",
      phoneNumber: "Phone Number",
      uploadLogo: "Upload Company Logo",
    },
    support: {
      userGuide: "User Guide",
    },
    circularityPage: {
      materials: "Materials",
      type: "Type",
      category: "Category",
      description: "Description",
      unit: "Unit",
      quantity: "Quantity",
      factor: "Factor",
      gwpUnit: "GWP / Unit",
      gwpTotal: "GWP Total (kg CO₂e)",
      biogenicTotal: "Total: Biogenic",
      fossilTotal: "Fossil",
      normalizedMass: "Normalized Mass (%)",
      utility: "Utility",
      input: "Input",
      inputmaterial: "Input Material",
      output: "Output",
      outputmaterial: "Output Material",
      endoflife: "End of Life",
      endoflifetreatment: "End of Life Treatment",
      normalizedGwp: "Normalized GWP",
    },
    EPDTable: {
      EPDTable: "EPD Table",
      category: "Category",
      manufacturer: "Manufacturer",
      plantOrGroup: "Plant or Group",
      name: "Name",
      description: "Description",
      programOperator: "Program Operator",
      verifierOrg: "Verifier Organization",
      plantGeography: "Plant Geography",
      declaredUnit: "Declared Unit",
      gwp: "GWP",
      updatedOn: "Updated On",
      leedCategory: "LEED Category",
      selectCategory: "Select a category",
      subcategory: "Subcategory",
      selectSubcategory: "Select a subcategory",
      selectManufacturer: "Select a manufacturer",
      selectPlantGeography: "Select a plant geography",
      min: "Min",
      max: "Max",
      mean: "Mean",
      stddev: "Standard Deviation",
      boxplotView: "Boxplot View",
    },
    EPDPlot: {
      GWPDistribution: "GWP Distribution",
      EPDPlot: "EPD Plot",
      gwpValues: "GWP Values",
      min: "Min",
      max: "Max",
      mean: "Mean",
      stddev: "Standard Deviation",
    },
    UtilityFactorTable: {
      description: "Description",
      productLife: "Product life (Years)",
      industryAvgLife: "Industry average (Years)",
      functionalUnits: "Functional units over life time",
      industryAvgUnits: "Industry average",
      calculations: "Utility factor",
    },
    InputMaterialTable: {
      description: "Description",
      im_recycled_content: "Recycled content (%)",
      im_reuse: "Reuse (%)",
      im_remanufactured: "Remanufactured (%)",
      im_bio_materials: "Biological materials (%)",
      im_mass: "Mass of Virgin material (%)",
    },
    EndOfLifeTreatmentTable: {
      description: "Description",
      eol_recycling_efficiency: "Process Efficiency for Recycling the product (%)",
      eol_feedstock_efficiency: "Process Efficiency for Recycled feedstock production (%)",
      eol_recycling_waste: "Waste from Recycling the product (%)",
      eol_feedstock_waste: "Waste from Recycled feedstock production (%)",
      eol_unrecoverable_waste: "Total Unrecoverable Waste (%)",
    },
    OutputMaterialTable: {
      description: "Description",
      om_recycling: "Recycled (%)",
      om_reuse: "Reused (%)",
      om_bio_composted: "Biological materials (Composted) (%)",
      om_bio_energy_recovery: "Biological materials (Energy Recovery) (%)",
      om_landfill_waste: "Waste to landfill or Energy Recovery (%)",
      set_default_recycled_values: "Set Default Recycled Values",
    },
  },
  FR: {
    contactInfo: "Emailus@vrtta.green | +1 (438) 882-5385",
    copyrightInfo: "Droits d'auteur © 2025",
    arivu: "ARIVU | ABCD [version 2025]",
    vrtta: "Solutions vertes VRTTA Inc. Tous droits réservés.",
    product: "Produits / Services",
    fleetPerformanceTitle: "Performance de la flotte",
    organizational: "Organisationnel",
    netZeroTitle: "Net Zéro",
    circularityTitle: "Circularité",
    supplier: "Fournisseur",
    utilizer: "Utilisateur",
    generateReport: "Générer un rapport",
    supply_chain: "Chaîne d'approvisionnement",
    organization: "Organisation",
    comparisonTitle: "Comparaison",
    reuseTitle: "Réutiliser",
    description: "",
    table: {
      product: "Produits / Services",
      supplier: "Fournisseur",
      leedCategory: "Catégorie LEED",
      subCategory: "Sous-catégorie",
      year: "Année",
      quantity: "Quantité",
      gwpTotal: "GWP Total (kg CO₂e)",
      gwpTotal_kg: "GWP Total (kg CO₂e)",
      gwpTotal_ton: "GWP Total (tonne CO₂e)",
      cost: "Coût($)",
      costPerGwp: "Coût / GWP",
      designInstallation: "Installation de Conception",
      location: "Emplacement",
      tableTitle: "Produits / Services",
      designInstallationsTitle: "Installations de Conception",
      displayAdditionalInfo: "Afficher des informations supplémentaires",
      collaborate: "Collaborer",
      trackView: "Vue Globale",
      structureView: "Vue structurelle",
      documentViewer: "Visionneuse de documents",
      toggleDetails: "Détails",
      organization: "Organisation",
      orgType: "Approche de contrôle",
      percentage: "Pourcentage",
    },
    buttons: {
      addRow: "Ajouter une ligne",
      duplicate: "Dupliquer",
      delete: "Supprimer",
      importCsv: "Importer Modèle",
      exportCsv: "Exporter Modèle",
      save: "Enregistrer",
      confirmDeletion: "Confirmer la suppression",
      confirmMessage: "Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?",
      confirm: "Supprimer",
      cancel: "Annuler",
    },
    comparison: {
      supplier: "Fournisseur",
      utilizer: "Utilisateur",
      supply_chain: "Chaîne d'approvisionnement",
      organization: "Organisation",
      inventoryAsOf: "Inventaire au",
      selectComparisonType: "Sélectionner le type de comparaison",
      comparisonBasedOn: "Comparaison basée sur",
      selectValuesToCompare: "Sélectionnez les valeurs à comparer",
      apply: "Appliquer",
      environmentalFootprint: "Empreinte environnementale",
      footprintSummary: "Résumé de l'empreinte",
      percentageDifference: "Différence en pourcentage",
      gwp: "GWP [kg CO₂e]",
      phases: "Phases",
      productPhase: "Produit",
      constructionPhase: "Construction",
      usePhase: "Utilisation",
      endOfLifePhase: "Fin de vie",
      benefitsPhase: "Avantages",
      scopes: "Cadres",
      scope1: "Cadre1",
      scope2: "Cadre2",
      scope3: "Cadre3",
      scope4: "Cadre4",
      total: "Total",
      products: "Produits",
      organizations: "Organisations",
      percentageDifferenceChart: "Différence en pourcentage (%)",
      lessThan: "% moins que",
      moreThan: "% plus que",
      value: "Valeur",
      exportDocx: "Générer le rapport",
      allUnits: "Toutes les unités sont calculées en kg CO₂e",
    },
    netZero: {
      scopes: 'Cadres ou catégorie de cadre 3'
    },
    phaseCard: {
      average: "Moyenne",
    },
    reuse: {
      product: "Produit",
      quantity: "Quantité de crédit",
      avoidedEmissions: "Émissions Évitées",
      donorCredit: "Émissions Évitées par Les Donateurs (kg CO₂e)",
      receiverCredit: "Émissions Évitées par Le Récepteur (kg CO₂e)",
      designInstallation: "Installation de Conception",
      selectAtLeastOne: "Veuillez sélectionner au moins un produit ou une installation de conception.",
      donorCredits: "émissions évitées par les donateurs",
      receiverCredits: "émissions évitées par le Récepteur",
      quantity: "Quantité",
      gridNotReady: "La grille n'est pas prête",
      csvFileName: "donnees_reutilisation.csv",
      reuseType: "Type de Réutilisation",
      supplier: "Fournisseur",
      utilizer: "Utilisateur",
      products: "Émissions Évitées",
      designInstallations: "Installations de Conception",
      exportAsCsv: "Exporter au CSV",
      saveChanges: "Enregistrer",
      loadingData: "Chargement des données...",
      credits: "Émissions Évitées",
      exportDocx: "Générer le rapport",
      generatedGraph: "Graphique des Émissions Évitées",
      totalDonorCredits: "Émissions Totales Évité par Les Donateurs",
      totalReceiverCredits: "Émissions Totales Évité par Le Récepteur",
    },
    login: {
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      invalidCredentials: "Nom d'utilisateur ou mot de passe invalide",
      enterUsername: "Entrez votre nom d'utilisateur",
      enterPassword: "Entrez votre mot de passe",
      login: "Connexion",
      notRegistered: "Pas encore inscrit?",
      createAccount: "Créer un compte",
      welcome: "Bienvenue",
    },
    register: {
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      recheckPassword: "Vérifiez le mot de passe",
      companyName: "Nom de l'entreprise",
      address: "Adresse",
      phoneNumber: "Numéro de téléphone",
      email: "Email",
      latitude: "Latitude",
      longitude: "Longitude",
      register: "S'inscrire",
      invalidCredentials: "Nom d'utilisateur ou mot de passe invalide",
      passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
      enterUsername: "Entrez votre nom d'utilisateur",
      enterPassword: "Entrez votre mot de passe",
      enterRecheckPassword: "Entrez à nouveau votre mot de passe",
      enterCompanyName: "Entrez le nom de votre entreprise",
      enterAddress: "Entrez votre adresse",
      enterPhoneNumber: "Entrez votre numéro de téléphone",
      enterEmail: "Entrez votre email",
      enterLatitude: "Entrez votre latitude",
      enterLongitude: "Entrez votre longitude",
    },
    termsAndConditions: {
      termsHeader: "Termes et Conditions",
      termsContent: ["Voici les termes et conditions...", "Plus de détails sur les termes et conditions..."],
      decline: "Refuser",
      accept: "Accepter",
    },
    lifeCycleAssessment: {
      title: "Évaluation du Cycle de Vie",
      orgTitle: "Évaluation des émissions de GES",
      generalInformation: "Informations Générales",
      referencePCR: "PCR de Référence",
      declaredUnit: "Unité Déclarée",
      scope: "Portée",
      lcaSoftware: "Logiciel LCA",
      epdUrl: "URL EPD",
      lciDatabase: "Base de Données LCI",
      lciaMethodology: "Méthodologie LCIA",
      cutOff: "Couper",
      allocationProcedure: "Procédure d'Allocation",
      primarySources: "Principales Sources De Données LCI",
      interpretation: "Interprétation",
      references: "Références / Facteurs Environnementaux",
      methodology: "Méthodologie",
    },
    systemBoundary: {
      supplierTitle: "Limite du système (du Berceau à la Porte)",
      utilizerTitle: "Limite du Système (Du Berceau à la Tombe)",
      productStage: "Étape du produit",
      rawMaterialAcquisition: "Acquisition de matières premières",
      transportToFactory: "Transport à l'Usine",
      manufacturing: "Fabrication",
      constructionStage: "Étape de construction",
      transportToSite: "Transport vers le Site",
      constructionInstallation: "Construction-Installation",
      useStage: "Étape d'utilisation",
      use: "Utilisation",
      maintenance: "Entretien",
      repair: "Réparation",
      replacement: "Remplacement",
      refurbishment: "Rénovation",
      endOfLifeStage: "Étape de fin de vie",
      deconstructionDemolition: "Déconstruction / Démolition",
      transportToWasteDisposal: "Transport vers les Déchets / Élimination",
      wasteProcessing: "Traitement des Déchets",
      disposalOfWaste: "Élimination des Déchets",
      beyondBuildingLifeCycle: "Au-delà du cycle de vie de la fabrication",
      reuseRecoveryRecyclingPotential: "Potentiel de Réutilisation-Récupération-Recyclage",
    },
    header: {
      supplier: "Fournisseur",
      utilizer: "Utilisateur",
      supply_chain: "Chaîne d'approvisionnement",
      organization: "Organisation",
      comparison: "Comparaison",
      reuse: "Réutiliser",
      circularity: "Circularité",
    },
    sideMenu: {
      support: "Support",
      settings: "Paramètres",
      profile: "Profil",
      logout: "Se déconnecter",
    },
    processTable: {
      processes: "Processus",
      lcaStage: "Étape LCA",
      process: "Processus",
      gwp: "GWP Total (kg CO₂e)",
      all: "Tout",
      product: "Produit",
      construction: "Construction",
      use: "Utilisation",
      endOfLife: "Fin de vie",
      benefits: "Avantages",
      biogenicTotal: "Total: Biogénique",
      fossilTotal: "Fossile",
      headers: {
        process: "Processus",
        gwp: "GWP Total (kg CO₂e)",
        lcaStage: "Étape LCA",
        biogenic_gwp: "GWP Biogénique Total (kg CO₂e)",
      },
    },
    scopeTable: {
      scope: "Emissions",
      all: "Tout",
      scope1: "Cadre 1",
      scope2: "Cadre 2",
      scope3: "Cadre 3",
      scope4: "Cadre 4 (Émission évitée)",
      headers: {
        category: "Catégorie",
        description: "Description",
        gwp_kg: "GWP Total (kg CO₂e)",
        gwp_ton: "GWP Total (tonne CO₂e)",
      },
    },
    processEnvFactorButton: {
      addRow: "Ajouter",
      deleteSelectedRow: "Supprimer",
      saveTable: "Enregistrer",
    },
    footprint: {
      footprintOverview: "Vue d'ensemble de l'empreinte",
      materialOverview: "Vue d'ensemble du matériau",
      product: "Produit",
      construction: "Construction",
      use: "Utilisation",
      endOfLife: "Fin de vie",
      benefits: "Avantages",
      unitsInfo: "Toutes les unités sont calculées en kg",
      unitsInfoCO2e: "Toutes les unités sont calculées en kg CO₂e",
      emissionGraph: "Graphique des émissions",
      detailsGraph: "Graphique des détails",
      environmentalFootprint: "Empreinte environnementale",
      materialFootprint: "Empreinte matérielle",
      totalWeight: "Poids Total :",
      gwpTotal: "GWP Total :",
      environmental: "Environnemental",
      material: "Matériel",
      biogenicGwpTotal: "GWP Biogénique Total :",
      dropdown: {
        all: "Tout",
        product: "Produit (A1-A3)",
        construction: "Construction (A4-A5)",
        use: "Utilisation (B)",
        endOfLife: "Fin de vie (C)",
        benefits: "Avantages (D)",
        rawMaterial: "A1 : Acquisition de Matières Premières",
        transportFactory: "A2 : Transport vers l'Usine",
        manufacturing: "A3 : Fabrication",
      },
      scopeDropdown: {
        scope1: "Cadre1",
        scope2: "Cadre2",
        scope3: "Cadre3",
        scope4: "Cadre4",
      },
    },
    envFactorTable: {
      envFactors: "Facteurs Environnementaux",
      type: "Type",
      category: "Catégorie",
      description: "Description",
      unit: "Unité",
      factor: "Facteur",
      quantity: "Quantité",
      uncertainty: "Incertitude",
      gwpTotal: "GWP Total (kg CO₂e)",
      gwpUnit: "Unité GWP",
      biogenicTotal: "Total: Biogénique",
      fossilTotal: "Fossile",
    },
    scopeCategoryDetailsTable: {
      scopeDetails: "Détails",
      calculationMethod: "Méthode",
      type: "Type",
      category: "Catégorie",
      description: "Description",
      unit: "Unité",
      factor: "Facteur",
      quantity: "Quantité",
      TotalCO2Eq: "Éq. CO₂ total (kg)",
      gwpUnit: "GWP/Unité",
    },
    GHGInfoTable: {
      ghgInfo: "Information de GHG",
    },
    profile: {
      loginInfo: "Informations de connexion",
      email: "Email",
      password: "Mot de passe",
      userProfile: "Profil de l'utilisateur",
      company: "Entreprise",
      address: "Adresse",
      phoneNumber: "Numéro de téléphone",
      uploadLogo: "Télécharger un logo d'entreprise",
    },
    support: {
      userGuide: "Guide de l'utilisateur",
    },
    circularityPage: {
      materials: "Matériaux",
      type: "Type",
      category: "Catégorie",
      description: "Description",
      unit: "Unité",
      quantity: "Quantité",
      factor: "Facteur",
      gwpUnit: "GWP / Unité",
      gwpTotal: "GWP Total (kg CO₂e)",
      biogenicTotal: "Total: Biogénique",
      fossilTotal: "Fossile",
      normalizedMass: "Masse Normalisée (%)",
      utility: "Utilité",
      input: "Saisir",
      inputmaterial: "Matériel d'entrée",
      outputmaterial: "Matériel de sortie",
      output: "Sortir",
      endoflife: "Fin de Vie",
      endoflifetreatment: "Traitement de fin de vie",
      normalizedGwp: "GWP Normalisé",
    },
    EPDTable: {
      epdTable: "Tableau EPD",
      category: "Catégorie",
      manufacturer: "Fabricant",
      plantOrGroup: "Usine ou Groupe",
      name: "Nom",
      description: "Description",
      programOperator: "Opérateur de programme",
      verifierOrg: "Organisation de vérification",
      plantGeography: "Géographie de l'usine",
      declaredUnit: "Unité déclarée",
      gwp: "GWP",
      updatedOn: "Mis à jour le",
      leedCategory: "Catégorie LEED",
      selectCategory: "Sélectionnez une catégorie",
      subcategory: "Sous-catégorie",
      selectSubcategory: "Sélectionnez une sous-catégorie",
      selectManufacturer: "Sélectionnez un fabricant",
      selectPlantGeography: "Sélectionnez une géographie d'usine",
      min: "Min",
      max: "Max",
      mean: "Moyenne",
      stddev: "Écart type",
      boxplotView: "Diagramme en boîte",
    },
    EPDPlot: {
      GWPDistribution: "Distribution GWP",
      EPDPlot: "Graphique EPD",
      gwpValues: "Valeurs GWP",
      min: "Min",
      max: "Max",
      mean: "Moyenne",
      stddev: "Écart type",
    },
    UtilityFactorTable: {
      description: "Description",
      productLife: "Durée de vie du produit (années)",
      industryAvgLife: "Moyenne de l'industrie (années)",
      functionalUnits: "Unités fonctionnelles sur la durée de vie",
      industryAvgUnits: "Moyenne de l'industrie",
      calculations: "Facteur d'utilité",  
    },
    InputMaterialTable: {
      description: "Description",
      im_recycled_content: "Contenu recyclé (%)",
      im_reuse: "Réutiliser (%)",
      im_remanufactured: "Remanufacturé (%)",
      im_bio_materials: "Matériaux biologiques (%)",
      im_mass: "Masse de matière vierge (%)",
    },
    EndOfLifeTreatmentTable: {
      description: "Description",
      eol_recycling_efficiency: "Efficacité du processus pour le recyclage du produit (%)",
      eol_feedstock_efficiency: "Efficacité du processus pour la production de matières premières recyclées (%)",
      eol_recycling_waste: "Déchets du recyclage du produit (%)",
      eol_feedstock_waste: "Déchets de la production de matières premières recyclées (%)",
      eol_unrecoverable_waste: "Total des déchets non récupérables (%)",
    },
    OutputMaterialTable: {
      description: "Description",
      om_recycling: "Recyclé (%)",
      om_reuse: "Réutilisé (%)",
      om_bio_composted: "Matériaux biologiques (Compostés) (%)",
      om_bio_energy_recovery: "Matériaux biologiques (Récupération d'énergie) (%)",
      om_landfill_waste: "Déchets en décharge ou récupération d'énergie (%)",
      set_default_recycled_values: "Utiliser les valeurs recyclées par défaut",
    },
  },
};
