import React, { useState, useEffect } from "react";
import Grid from "../environment-page-components/Grid";
import CircularityButton from "./CircularityButton";
import { translations } from "../app_components/Translation";

function InputMaterialTable({ data, selectedLanguage }) {
  const [rowData, setRowData] = useState([]);
  const selectedText = translations[selectedLanguage].InputMaterialTable || {};

  console.log(data);

  // Load initial data when `data` prop changes and calculate mass of virgin material
  useEffect(() => {
    if (data && data.length > 0) {
      const updatedData = data.map((item) => {
        // Destructure the item for easier access
        const {
          id,
          env_id,
          description,
          im_recycled_content,
          im_reuse,
          im_remanufactured,
          im_bio_materials,
          im_mass_virgin_material,
          normalizedMass,
        } = item;

        // Calculate the mass of virgin material if normalizedMass is available
        let calculatedMassVirginMaterial = im_mass_virgin_material; // Default to existing value if present
        if (normalizedMass) {
          calculatedMassVirginMaterial =
            normalizedMass *
            (1 -
              im_recycled_content -
              im_reuse -
              im_remanufactured -
              im_bio_materials) * 100;
        }

        return {
          id,
          env_id,
          description,
          im_recycled_content,
          im_reuse,
          im_remanufactured,
          im_bio_materials,
          im_mass_virgin_material: calculatedMassVirginMaterial,
          normalizedMass, // Keep it for internal use but not for CircularityButton
        };
      });

      setRowData(updatedData);
    }
  }, [data]); // Runs whenever `data` changes

  const columnDefs = [
    { field: "description", headerName: "Description", editable: true },
    {
      field: "im_recycled_content",
      headerName: selectedText.im_recycled_content,
      editable: true,
    },
    {
      field: "im_reuse",
      headerName: selectedText.im_reuse,
      editable: true
    },
    {
      field: "im_remanufactured",
      headerName: selectedText.im_remanufactured,
      editable: true,
    },
    {
      field: "im_bio_materials",
      headerName: selectedText.im_bio_materials,
      editable: true,
    },
    {
      field: "im_mass_virgin_material",
      headerName: selectedText.im_mass,
      editable: false,
    },
  ];

  const onCellValueChanged = (params) => {
    setRowData((prevData) =>
      prevData.map((row) => {
        if (row.description === params.data.description) {
          const updatedRow = {
            ...row,
            [params.colDef.field]: params.value, // Update the field that changed
          };

          // Recalculate 'im_mass_virgin_material' if any of the related fields change
          const {
            im_recycled_content,
            im_reuse,
            im_remanufactured,
            im_bio_materials,
            normalizedMass,
          } = updatedRow;
          if (normalizedMass) {
            const massVirgin =
              normalizedMass *
              (1 -
                im_recycled_content -
                im_reuse -
                im_remanufactured -
                im_bio_materials);
            updatedRow.im_mass_virgin_material = massVirgin;
          }

          return updatedRow;
        }
        return row;
      })
    );
  };

  // Create a version of rowData without the `normalizedMass` field
  const rowDataWithoutNormalizedMass = rowData.map(
    ({ normalizedMass, ...rest }) => rest
  );

  return (
    <div>
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        onGridReady={() => { }}
        rowSelection="single"
        onSelectionChanged={() => { }}
        getRowStyle={() => { }}
        customMinHeight={200}
        customMaxheight={500}
        onColumnMoved={() => { }}
        onDragStopped={() => { }}
        gridOptions={{ onCellValueChanged }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", }}>
        <CircularityButton data={rowDataWithoutNormalizedMass} />
      </div>
    </div>
  );
}

export default InputMaterialTable;
