import React, { useEffect, useState } from 'react';
import '../style/components-style/Collaborate.css'; // Import the CSS file
import config from '../config'; // Assuming you have a config file for the API URL
import { useAuth } from "../authentication/AuthProvider";

const Collaborate = ({ collaborateInfo, fetchData }) => {
    const { user } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(""); // State for the selected user
    const [email, setEmail] = useState(""); // State for the email input

    // Fetch the users from the 'users' table and filter based on the current user's ID in suppliers_list
    useEffect(() => {
        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                table_name: 'users',
                attribute: '*', // Assuming you want to display all attributes including suppliers_list
                condition: '1' // Fetch all users
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                // Filter users whose suppliers_list contains the current user's ID
                const filteredUsers = (data || []).filter(userObj => {
                    const suppliersList = JSON.parse(userObj.suppliers_list || '[]');
                    return suppliersList.includes(user.username); // user.username is the current user's ID
                });
                setUsers(filteredUsers);
            })
            .catch(error => console.error('Error fetching users:', error));
    }, [user.username]);

    // Function to handle sending for outside validation
    const handleSendForOutsideValidation = () => {
        if (!email) {
            alert('Please enter an email.');
            return;
        }

        fetch(`${config.apiUrl}/send_for_outside_validation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email, // Use the state variable for the email
                row_id: collaborateInfo.id, // Assuming you have a row_id or similar identifier in collaborateInfo
                sender: user 
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                alert('Validation sent: ' + data.message);
            })
            .catch(error => console.error('Error sending for outside validation:', error));
    };

    // Function to handle sending for inside validation
    const handleSendForInsideValidation = () => {
        if (!selectedUser) {
            alert('Please select a user.');
            return;
        }

        fetch(`${config.apiUrl}/send_for_inside_validation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                collaborator_id: selectedUser,
                row_id: collaborateInfo.id, // Assuming you have a row_id or similar identifier in collaborateInfo
                is_supplier: true,
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                alert('Validation sent: ' + data.message);
            })
            .catch(error => console.error('Error sending for inside validation:', error));
    };

    const handleCompleteValidation = () => {
        fetch(`${config.apiUrl}/update_data`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                table_name: 'suppliers_products',  
                data: {
                    validated: 1, 
                },
                condition: `id = '${collaborateInfo.id}'` 
            })
        })
        .then(response => {
            if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
            return response.json();
        })
        .then(data => {
            alert('Validation completed: ' + data.status);
            fetchData(); // Re-fetch the table data after validation
        })
        .catch(error => console.error('Error completing validation:', error));
    };

    
    // Check if the current user is the collaborator
    const isCollaborator = collaborateInfo.collaborator_id === user.username;

    return (
        <div className="collaborate">
            {isCollaborator ? (
                <div className='collaborator-text'>
                    <p>
                        This product was sent to you by<br />
                        <b>{collaborateInfo.user_id}</b> on <b>{collaborateInfo.date_modified}</b><br />
                        as a validated product and is not editable.
                    </p>
                </div>
            ) : (
                <>
                    <p>Send to a user outside of this application:</p>
                    <form>
                        <div>
                            <label>Contact Person:</label>
                            <input
                                type="text"
                                value={collaborateInfo.detail_contact_person || 'N/A'}
                                readOnly
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="email"
                                value={email} // Controlled input for email
                                onChange={e => setEmail(e.target.value)} // Update state on input change
                                placeholder="Enter email"
                            />
                        </div>
                        <button type="button" onClick={handleSendForOutsideValidation}>
                            Send To Outside Utilizer
                        </button>
                    </form>
                    <p>Send to a user inside this application:</p>
                    <form>
                        <div>
                            <label>Select User:</label>
                            <select
                                value={selectedUser}
                                onChange={e => setSelectedUser(e.target.value)}
                            >
                                <option value="">Select a user</option>
                                {users.map((userObj, index) => (
                                    <option key={index} value={userObj.user_id}>
                                        {userObj.user_id}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="button" onClick={handleSendForInsideValidation}>
                            Send To Inside Utilizer
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default Collaborate;
